import React from 'react';
import {StyleSheet, Text as NativeText} from 'react-native';
import {Text} from 'react-native-elements';
import PropTypes from 'prop-types';
import {Linking} from 'expo';

const styles = StyleSheet.create({
  link: {
    color: '#2196f3',
    textDecorationLine: 'underline',
  },
});

const Link = ({href, children, style, onPress, ...rest}) => {
  const computedStyle = StyleSheet.flatten([styles.link, style]);

  return (
    <Text
      accessibilityRole='link'
      style={computedStyle}
      onPress={href ? () => Linking.openURL(href) : onPress}
      {...rest}
    >
      {children}
    </Text>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  style: NativeText.propTypes.style,
  onPress: PropTypes.func,
};

export default Link;
