import React, {useEffect, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  AccessibilityInfo,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  titleLabel: {
    margin: 16,
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  subItemLabel: {
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 'bold',
    fontSize: 14,
  },
});

const DrawerSubMenu = (props) => {
  const navigate = (section) => {
    props.navigation.navigate('About', {section});
    props.navigation.closeDrawer();

    if (props.scrollUpdater) {
      props.scrollUpdater.emit('scroll', section);
    }
  };

  const [screenReaderEnabled, setScreenReaderEnabled] = useState(false);

  if (Platform.OS !== 'web') {
    useEffect(() => {
      AccessibilityInfo.addEventListener('change', (reader) =>
        setScreenReaderEnabled(reader)
      );
      AccessibilityInfo.fetch().done((isEnabled) => {
        setScreenReaderEnabled(isEnabled);
      });
    }, []);
  }

  return (
    <View accessibilityLabel='About'>
      <View>
        <Text
          style={[
            styles.titleLabel,
            !props.activeSection && {color: props.color},
            props.labelStyle,
            props.extraLabelStyle,
          ]}
        >
          {props.title}
        </Text>
      </View>
      {!screenReaderEnabled &&
        props.items.map((item, index) => (
          <View key={index}>
            <Text
              onPress={() => navigate(item)}
              style={[
                props.labelStyle,
                styles.subItemLabel,
                props.activeSection &&
                  props.activeSection === item &&
                  props.color && {color: props.color},
                props.extraLabelStyle,
              ]}
            >
              {item}
            </Text>
          </View>
        ))}
    </View>
  );
};

DrawerSubMenu.propTypes = {
  color: PropTypes.string,
  labelStyle: PropTypes.object,
  extraLabelStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    closeDrawer: PropTypes.func.isRequired,
  }).isRequired,
  activeSection: PropTypes.string,
  scrollUpdater: PropTypes.object,
};

export default DrawerSubMenu;
