import React from 'react';
import {NEXT, SECTION_HEAD} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {
  EXPECT_BENEFITS,
  RECEIVE_BENEFITS,
  BENEFITS_OTHER_VALUE,
  BENEFITS_SS_VALUE,
  BENEFITS_SSD_VALUE,
  BENEFITS_SSI_VALUE,
  BENEFITS_TANF_VALUE,
  BENEFITS_TOTAL,
  BENEFITS_UNEMPLOYMENT_VALUE,
  toCurrency,
} from '../../sharedFields';
import {useReduxMemo} from '../../veraRedux';

const BenefitsSummary = (props) => {
  const benefits = [
    BENEFITS_OTHER_VALUE,
    BENEFITS_SS_VALUE,
    BENEFITS_SSD_VALUE,
    BENEFITS_SSI_VALUE,
    BENEFITS_TANF_VALUE,
    BENEFITS_UNEMPLOYMENT_VALUE,
  ];

  const benefitsTotal = useReduxMemo(
      BENEFITS_TOTAL,
      (getField) => {
        if (!getField(RECEIVE_BENEFITS)
          && !getField(EXPECT_BENEFITS)) {
          return 0;
        }

        const total = benefits.reduce(
            (total, field) => {
              return total + (getField(field) || 0);
            }, 0
        );
        return total;
      },
      [...benefits, EXPECT_BENEFITS, RECEIVE_BENEFITS]);

  const will = props[EXPECT_BENEFITS] ? 'will ' : '';
  // eslint-disable-next-line max-len
  const text = `You ${will}receive about ${toCurrency(benefitsTotal)} in benefits each month.`;

  return (
    <YesNoQuestion
      title={text}
      subTitle="Does this sound right?"
      onYes={() => {
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        props.navigation.navigate(SECTION_HEAD);
      }} />
  );
};

BenefitsSummary.useFields = [
  EXPECT_BENEFITS,
  RECEIVE_BENEFITS,
];

BenefitsSummary.showScreen =
  (props) => props[RECEIVE_BENEFITS] || props[EXPECT_BENEFITS];

BenefitsSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
  }),
};

export default BenefitsSummary;
