
import React from 'react';
import PropTypes from 'prop-types';
import AboutSection from './AboutSection';
import {StyleSheet, Text} from 'react-native';
import {customStyles} from '../../../config/Styles';
import Paragraph from '../../../layout/Paragraph';

/* eslint-disable max-len */
const terms = [
  {
    term: 'Appearance Bond',
    definition: 'When a person uses their own money to secure their pretrial release from jail.',
  },
  {
    term: 'Arraignment',
    definition: 'A court proceeding where a person charged with a crime is formally advised of those charges.',
  },
  {
    term: 'Asset',
    definition: 'Money or property that a person owns and controls.',
  },
  {
    term: 'Bail',
    definition: 'Money or property posted that is held as insurance that a person will return for future hearings.',
  },
  {
    term: 'Cash assistance',
    definition: 'Cash benefits provided by the government. Examples include Temporary Assistance for Needy Families (TANF) and Safety Net Assistance (SNA).',
  },
  {
    term: 'Cash Bond',
    definition: 'Cash paid to a court to cover the total amount of bail.',
  },
  {
    term: 'Cash in pocket',
    definition: 'Cash on one’s person, physically and immediately available.',
  },
  {
    term: 'Checking account',
    definition: 'A type of bank account from which money can be withdrawn via check, a debit card, or a cash withdrawal.',
  },
  {
    term: 'Credit card',
    definition: 'A card used to pay for expenses without cash or immediately liquid money. A person’s line of credit is determined by their use of credit and their credit card company.',
  },
  {
    term: 'Expenses',
    definition: 'Cost required to pay for something, like bills for housing or money spent on food.',
  },
  {
    term: 'Felony',
    definition: 'A serious criminal charge punishable by more than one year in jail.',
  },
  {
    term: 'Income from employment',
    definition: 'Income earned by working a job, or from retirement or pension plans. ',
  },
  {
    term: 'Income from benefits',
    definition: 'Income received via public assistance and monetary benefits.',
  },
  {
    term: 'Insurance company bail bond',
    definition: 'Also known as a bail bond. Bail that is guaranteed and tendered by private, for-profit bail bond agencies.',
  },
  {
    term: 'Misdemeanor',
    definition: 'A criminal charge punishable by up to one year in jail.',
  },
  {
    term: 'Monetary benefits',
    definition: 'Public assistance that has a cash value and can be used like cash to make payments for a variety of expenses.',
  },
  {
    term: 'No ability to pay',
    definition: 'When a person has no financial income, or their financial obligations exceed their income, such that payment of any amount of bail would cause undue hardship.',
  },
  {
    term: 'Partially secured bond',
    definition: 'Also known as a deposit bond. Bail that is secured by a deposit of money with a court, usually between one to ten percent of the total bail amount.',
  },
  {
    term: 'Regular monthly expenses',
    definition: 'Expenses or bills a person anticipates paying on a monthly basis, including rent, transportation, student loans, or groceries, among others.',
  },
  {
    term: 'Release on recognizance',
    definition: 'Also known as a personal recognizance bond. When a person is released pretrial without the need to pay any money bail at all.',
  },
  {
    term: 'Social Security Disability (SSD)',
    definition: 'Benefits paid to people who have a disability that prevents them from working before reaching retirement age.',
  },
  {
    term: 'Social Security Income',
    definition: 'Benefits paid to people who worked but are now retired.',
  },
  {
    term: 'Supplemental Security Income (SSI)',
    definition: 'Monetary benefits paid to people with limited income who are older than 65, blind, or disabled.',
  },
  {
    term: 'Surety Bond',
    definition: 'A promise by a family member or friend to pay all or part of the bail if a person fails to appear in court or otherwise violates the release conditions.',
  },
  {
    term: 'Unemployment income',
    definition: 'Monetary benefits paid as a result of unemployment.',
  },
  {
    term: 'Unsecured bond',
    definition: 'Bail with dollar amount assigned that is only paid to the court if the person fails to appear in court or otherwise violates the release conditions.',
  },
];
  /* eslint-enable max-len */

const styles = StyleSheet.create({
  paragraph: {
    textAlign: 'left',
    width: '100%',
  },
  termHeading: {
    fontWeight: '900',
  },
});

const definitionStyle = StyleSheet.flatten([
  customStyles.secondaryText,
  styles.paragraph,
]);

const termHeadingStyle = StyleSheet.flatten([
  styles.termHeading,
]);

const Term = ({term, definition}) => (
  <React.Fragment>
    <Paragraph
      accessibilityLabel={`${term} is: ${definition}`}
      style={definitionStyle}
      large>
      <Text
        style={termHeadingStyle}>
        {term}
      </Text>&#8195;
      {definition}
    </Paragraph>
  </React.Fragment>
);

Term.propTypes = {
  term: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
};

const Glossary = (props) => {
  return (
    <AboutSection title='Glossary' {...props}>
      {terms.map( (item, index) => (
        <Term key={index} term={item.term} definition={item.definition} />
      ))}
    </AboutSection>
  );
};

export default Glossary;
