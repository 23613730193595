import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import {EXPECT_BENEFITS, RECEIVE_BENEFITS} from '../../sharedFields';
import YesNoQuestion from '../../components/YesNoQuestion';
import {useReduxState} from '../../veraRedux';

const ExpectBenefits = (props) => {
  const [, setExpectBenefits] = useReduxState(EXPECT_BENEFITS);
  const [, setReceiveBenefits] = useReduxState(RECEIVE_BENEFITS);

  return (
    <YesNoQuestion
      title="Do you expect to receive income from benefits in the next 30 days?"
      onYes={() => {
        setExpectBenefits(true);
        setReceiveBenefits(false);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setExpectBenefits(false);
        props.navigation.push(NEXT);
      }} />
  );
};

ExpectBenefits.useFields = [
  RECEIVE_BENEFITS,
];

ExpectBenefits.showScreen = (props) => !props[RECEIVE_BENEFITS];

ExpectBenefits.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ExpectBenefits;
