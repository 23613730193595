import React, { useState, useEffect } from 'react';
import { ViewPropTypes, Platform } from 'react-native';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import InputScroll from '../components/InputScroll';

const getSection = navigation =>
  navigation && navigation.dangerouslyGetParent().getParam('section');

const makeScrollable = (Component, scrollUpdater, scrollProps) => {
  const ScrollableComponent = React.forwardRef(({ style, ...props }, ref) => {
    const [scrollTo, setScrollTo] = useState();
    const [section, setSection] = useState(getSection(props.navigation));

    const scrollDelegate = position => {
      setScrollTo(position);
    };

    useEffect(() => {
      props.navigation.addListener('didFocus', () => {
        setSection(getSection(props.navigation));
      });

      if (scrollUpdater) {
        scrollUpdater.on('scroll', section => setSection(section));
      }
    }, []);

    const RenderedComponent = (
      <Component
        ref={ref}
        {...props}
        scrollTo={scrollDelegate}
        scrollSection={section}
      />
    );

    return Platform.OS === 'web' ? (
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          flexGrow: 1,
        }}
      >
        {RenderedComponent}
      </div>
    ) : (
      <InputScroll
        contentContainerStyle={{ flexGrow: 1 }}
        style={style}
        scrollTo={scrollTo}
        {...scrollProps}
      >
        {RenderedComponent}
      </InputScroll>
    );
  });

  const displayName =
    Component.displayName ||
    (typeof Component === 'function' && Component.name) ||
    (typeof Component === 'object' && Component.constructor.name);

  ScrollableComponent.displayName = `Scrollable<${displayName}>`;
  hoistNonReactStatics(ScrollableComponent, Component);

  ScrollableComponent.propTypes = {
    style: ViewPropTypes.style,
    navigation: PropTypes.shape({
      addListener: PropTypes.func.isRequired,
    }),
  };

  return ScrollableComponent;
};

export default makeScrollable;
