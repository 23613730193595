import {useState} from 'react';


const useErrorState = (fields) => {
  const setValueMap = {};
  const valueMap = {};

  let hasError = false;
  for (const field of fields) {
    const [value, setValue] = useState(false);
    setValueMap[field] = setValue;
    valueMap[field] = value;

    hasError = hasError || value;
  }

  const setFieldError = (field) => (value) => setValueMap[field](value);

  return [valueMap, setFieldError, hasError];
};

export default useErrorState;
