import React, {useState} from 'react';
import {Text, StyleSheet, View, ViewPropTypes} from 'react-native';
import Link from '../components/Link';
import {customStyles} from '../config/Styles';
import PropTypes from 'prop-types';
import OverlayScroll from '../layout/OverlayScroll';
import colors from '../config/colors';
import Paragraph from '../layout/Paragraph';

const styles = StyleSheet.create({
  container: {
    height: 300,
  },
  largeContainer: {
    height: 400,
  },
  body: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  header: {
    flexShrink: 1,
  },
  subHeader: {
    flexShrink: 1,
  },
  privacy: {
    fontSize: 14,
  },
  buttons: {},
});

const Question = (props) => {
  const {
    children,
    title,
    subTitle,
    containerStyle,
    bodyStyle,
    headerStyle,
    subHeaderStyle,
    buttonGroupStyle,
    privacyNote,
  } = props;

  const computedContainerStyle = StyleSheet.flatten([
    subTitle || privacyNote ? styles.largeContainer : styles.container,
    containerStyle,
  ]);

  const computedBodyStyle = StyleSheet.flatten([styles.body, bodyStyle]);

  const computedHeaderStyle = StyleSheet.flatten([styles.header, headerStyle]);

  const computedButtonGroupStyle = StyleSheet.flatten([
    styles.buttons,
    buttonGroupStyle,
  ]);

  const computedSubHeaderStyle = StyleSheet.flatten([
    styles.subHeader,
    subHeaderStyle,
  ]);

  const [showHelp, setShowHelp] = useState(false);

  return (
    <View style={computedContainerStyle}>
      <OverlayScroll
        title='Privacy'
        completeTitle='Done'
        onComplete={() => setShowHelp(false)}
        onBackdropPress={() => setShowHelp(false)}
        isVisible={showHelp}
        containerStyle={{
          padding: 20,
          backgroundColor: colors.darkGray,
        }}
        headerStyle={{
          color: 'white',
        }}
      >
        <Paragraph style={{color: 'white'}}>
          Your privacy is a top priority. Any information provided to the
          calculator will only be used to determine a person&apos;s ability to
          pay bail. The data will not be saved. Once you close out of the app,
          your data is erased.
        </Paragraph>
      </OverlayScroll>

      <View style={computedBodyStyle}>
        <View style={computedHeaderStyle}>
          <Text style={customStyles.headerText} accessibilityRole='header'>
            {title}
          </Text>
        </View>
        {subTitle && (
          <View style={computedSubHeaderStyle}>
            <Text style={customStyles.secondaryText}>{subTitle}</Text>
          </View>
        )}
        <View style={computedButtonGroupStyle}>{children}</View>
        <View accessibilityViewIsModal={showHelp} style={{paddingTop: 10}}>
          {privacyNote && (
            <Link style={styles.privacy} onPress={() => setShowHelp(true)}>
              Is this private?
            </Link>
          )}
        </View>
      </View>
    </View>
  );
};

Question.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  style: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
  bodyStyle: ViewPropTypes.style,
  headerStyle: ViewPropTypes.style,
  subHeaderStyle: ViewPropTypes.style,
  buttonGroupStyle: ViewPropTypes.style,
  privacyNote: PropTypes.bool,
};

export default Question;
