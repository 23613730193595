export const SET_FORM = 'bail-app/form/set';
export const SET_FORM_CALCULATED = 'bail-app/form/calculate';
export const SET_FORM_CLEAR = 'bail-app/form/clear';

// trackedFields maps all fields to the calculations which they
// comprise. It will be used to automated updating calcuations
// whenever a field changes and it has been part of a calculation
// We put wrap the tracking object in a proxy in an enclosure to
// limit munging the state, and we expose a reset() method. Without
// the need for reset, "const trackedFields = {};" would suffice.
const trackedFields = (function() {
  let backingStore = {};
  const wrapper = {
    get store() {
      return backingStore;
    },
  };

  const handler = {
    get: (obj, prop) =>
      prop === 'reset'
        ? () => {
            backingStore = {};
          }
        : obj.store[prop],
    set: (obj, prop, value) => {
      if (prop === 'reset') {
        throw new Error('Cannot overwrite reset');
      }

      obj.store[prop] = value;
      return true;
    },
  };

  return new Proxy(wrapper, handler);
})();

// getField returns a field value from the state's form
// we pass this function as a callback to calulation,
// with all parameters except "field, defaultValue" applied
const getField = form => key => calculation => (field, defaultValue) => {
  if (!trackedFields[field]) {
    trackedFields[field] = {};
  }

  if (!trackedFields[field][key]) {
    // it is import to curry the form here so that when
    // setField will re-compute the transactions, we
    // will use the current form state, and NOT what is in
    // our closure here for getField
    trackedFields[field][key] = curriedForm =>
      calculation.bind(null, getField(curriedForm)(key)(calculation));
  }

  const value = form[field];

  return value === undefined ? defaultValue : value;
};

const setField = form => field => value => {
  form[field] = value;
  if (trackedFields[field]) {
    for (const tracked in trackedFields[field]) {
      if (Object.prototype.hasOwnProperty.call(trackedFields[field], tracked)) {
        if (trackedFields[field][tracked]) {
          // update the calculation with the stored computation,
          // passing the current state's form
          const calculatedValue = trackedFields[field][tracked](form)();
          setField(form)(tracked)(calculatedValue);
        }
      }
    }
  }

  return form[field];
};

export default function reducer(state = { form: {} }, action) {
  const { ...form } = state.form;

  switch (action.type) {
    case SET_FORM:
      if (action.payload) {
        for (const key in action.payload) {
          if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
            setField(form)(key)(action.payload[key]);
          }
        }
      }

      return { ...state, form };
    case SET_FORM_CALCULATED:
      if (action.payload) {
        for (const key in action.payload) {
          if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
            const calculation = action.payload[key];
            // perform the calculation. calls to the getField callback
            // will register each gotten field with this calculation
            // to enable auto-recalculation
            const value = calculation(getField(form)(key)(calculation));
            setField(form)(key)(value);
          }
        }
      }
      return { ...state, form };
    case SET_FORM_CLEAR:
      trackedFields.reset();
      return { form: {} };
    default:
      return state;
  }
}

export function setForm(key, value) {
  return {
    type: SET_FORM,
    payload: {
      [key]: value,
    },
  };
}

export function setFormCalculated(key, calculation) {
  return {
    type: SET_FORM_CALCULATED,
    payload: {
      [key]: calculation,
    },
  };
}

export function setFormClear() {
  return {
    type: SET_FORM_CLEAR,
  };
}
