import React from 'react';
import { Platform } from 'react-native';
import { createLinearNavigator } from './linearNavigator';
export { NEXT, SECTION_HEAD } from './linearNavigator';
import leftHeaderHoc from './leftHeaderHoc';
import RightHeader from './RightHeader';
import makeScrollable from './makeScrollable';
import glossaryEvents from 'tiny-emitter/instance';

const SEP_CHAR = '/';

const tranformScreen = defaultVeraOptions => useLeftHeader => (
  entry,
  path,
  group
) => {
  const veraOptions = Object.assign({}, defaultVeraOptions, entry.veraOptions);
  const entryScreen = veraOptions.makeScrollable
    ? makeScrollable(entry.screen)
    : entry.screen;

  const Screen = veraOptions.containerHoc
    ? veraOptions.containerHoc(entryScreen, glossaryEvents)
    : entryScreen;

  return {
    screen: Screen,
    path,
    navigationOptions:
      entry.screen.navigationOptions ||
      (({ navigation }) => ({
        title: entry.title || group,
        headerLeft: useLeftHeader ? leftHeaderHoc(navigation) : undefined,
        headerRight: (
          <RightHeader
            onGlossary={() => glossaryEvents.emit('showGlossary', true)}
          />
        ),
        headerBackTitle: Platform.OS === 'ios' ? 'Back' : ' ',
      })),
    mapState: veraOptions.mapStateHoc(entry.screen),
    showScreen: entry.screen.showScreen,
  };
};

export const createVeraNavigator = (screens, config, getState) => {
  const transformed = {};
  const { defaultVeraOptions, ...remainingConfig } = config;
  let initialRouteName;

  const transform = tranformScreen(defaultVeraOptions);
  let currentTransform = transform(true);
  for (const group of screens) {
    for (const entry of group.screens) {
      const path = `${group.name}${SEP_CHAR}${entry.name}`;

      const screenConfig = currentTransform(entry, path, group.name);

      if (!initialRouteName) {
        initialRouteName = path;
        currentTransform = transform(false);
      }

      if (!transformed[group.name]) {
        transformed[group.name] = {
          screens: [],
          sectionLink: group.sectionLink !== false,
        };
      }

      transformed[group.name].screens.push(screenConfig);
    }
  }

  const composedConfig = Object.assign(
    {},
    { initialRouteName },
    remainingConfig
  );

  const navigator = createLinearNavigator(
    transformed,
    composedConfig,
    getState
  );

  return navigator;
};
