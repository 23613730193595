import React from 'react';
import { Overlay } from 'react-native-elements';
import { Platform } from 'react-native';

const WebCompatibleOverlay = props => {
  const RenderedOverlay = (
    <Overlay overlayStyle={{ borderStyle: 'none' }} {...props} />
  );

  return Platform.OS === 'web' ? (
    <div style={{ zIndex: 9999, display: props.isVisible ? 'block' : 'none' }}>
      {RenderedOverlay}
    </div>
  ) : (
    { RenderedOverlay }
  );
};

export default WebCompatibleOverlay;
