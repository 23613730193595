import {useState, useMemo} from 'react';
import {createStore as reduxCreateStore} from 'redux';
import {setForm, setFormCalculated, setFormClear} from './reducer';

let store;
let initialized = {};

const permute = (key) => (permuteHook) => (value) => {
  store.dispatch(setForm(key, value));
  return permuteHook(value);
};

export function useReduxState(key, initialValue) {
  if (!initialized[key]) {
    store.dispatch(setForm(key, initialValue));
    initialized[key] = true;
  } else {
    initialValue = getState().form[key];
  }

  const [valueHook, permuteHook] = useState(initialValue);

  return [valueHook, permute(key)(permuteHook)];
}

export function useReduxMemo(key, calculation, deps) {
  if (!initialized[key]) {
    store.dispatch(setFormCalculated(key, calculation));
    initialized[key] = true;
  }

  const form = getState().form;

  return useMemo( () => form[key],
      deps.map(
          (dep) => form[dep],
      ));
}

export function createStore(reducer) {
  store = reduxCreateStore(reducer);
  return store;
}

export function getState() {
  return store && store.getState();
}

export function resetState() {
  initialized = {};
  store.dispatch(setFormClear());
}
