import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {EXPECT_INCOME, RECEIVE_INCOME} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';


const IncomeHead = (props) => {
  const [, setReceiveIncome] = useReduxState(RECEIVE_INCOME);
  const [, setExpectIncome] = useReduxState(EXPECT_INCOME);

  return (
    <YesNoQuestion
      title="Do you currently receive income from employment?"
      onYes={() => {
        setReceiveIncome(true);
        setExpectIncome(false);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setReceiveIncome(false);
        props.navigation.push(NEXT);
      }} />
  );
};

IncomeHead.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default IncomeHead;
