import React from 'react';
import {StyleSheet, View, ViewPropTypes} from 'react-native';
import {Text} from 'react-native-elements';
import PropTypes from 'prop-types';
import {customStyles} from '../config/Styles';

const Section = (props) => {
  const {
    containerStyle,
    headerStyle,
    headerTextStyle,
    bodyStyle,
    title,
    children,
    ...rest
  } = props;

  const computedHeaderStyle = StyleSheet.flatten([
    customStyles.header,
    headerStyle,
  ]);

  const computedheaderTextStyle = StyleSheet.flatten([
    customStyles.headerText,
    headerTextStyle,
  ]);

  const computedBodyStyle = StyleSheet.flatten([
    customStyles.body,
    bodyStyle,
  ]);

  return (
    <View style={containerStyle} {...rest}>
      {title &&
      <View style={computedHeaderStyle}>
        <Text
          accessibilityRole='header'
          style={computedheaderTextStyle}>
          {title}
        </Text>
      </View>
      }
      <View style={computedBodyStyle}>
        {children}
      </View>
    </View>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyle: ViewPropTypes.style,
  headerStyle: ViewPropTypes.style,
  headerTextStyle: PropTypes.object,
  bodyStyle: ViewPropTypes.style,
  title: PropTypes.string,
};

export default Section;
