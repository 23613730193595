import React from 'react';
import {Platform} from 'react-native';
import {HeaderBackButton} from 'react-navigation';

const leftHeaderHoc = (navigation) => {
  const LeftHeader = (props) =>
    <HeaderBackButton
      onPress={() => navigation.goBack(null)}
      title= {Platform.OS === 'ios' ? 'Back' : ''}
      backTitleVisible={Platform.OS === 'ios'} />;
  return LeftHeader;
};

export default leftHeaderHoc;
