import React from 'react';
import {NEXT, SECTION_HEAD} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {RECEIVE_EXPENSES,
  EXPENSES_CHILD_VALUE,
  EXPENSES_ELECTRIC_GAS_VALUE,
  EXPENSES_FOOD_VALUE,
  EXPENSES_HOUSING_VALUE,
  EXPENSES_INTERNET_TV_VALUE,
  EXPENSES_LOANS_VALUE,
  EXPENSES_MEDICAL_VALUE,
  EXPENSES_OTHER_VALUE,
  EXPENSES_PHONE_VALUE,
  EXPENSES_TOTAL,
  EXPENSES_TRANSPORTATION_VALUE,
  toCurrency,
} from '../../sharedFields';
import {useReduxMemo} from '../../veraRedux';

const ExpensesSummary = (props) => {
  const expenses = [
    EXPENSES_CHILD_VALUE,
    EXPENSES_ELECTRIC_GAS_VALUE,
    EXPENSES_FOOD_VALUE,
    EXPENSES_HOUSING_VALUE,
    EXPENSES_INTERNET_TV_VALUE,
    EXPENSES_LOANS_VALUE,
    EXPENSES_MEDICAL_VALUE,
    EXPENSES_OTHER_VALUE,
    EXPENSES_PHONE_VALUE,
    EXPENSES_TRANSPORTATION_VALUE,
  ];

  const expensesTotal = useReduxMemo(
      EXPENSES_TOTAL,
      (getField) => {
        if (!getField(RECEIVE_EXPENSES)) {
          return 0;
        }

        const total = expenses.reduce(
            (total, field) => {
              return total + (getField(field) || 0);
            }, 0
        );
        return total;
      },
      [...expenses, RECEIVE_EXPENSES]);

  const text = `You have ${toCurrency(expensesTotal)} in expenses per month.`;

  return (
    <YesNoQuestion
      title={text}
      subTitle="Does this sound right?"
      onYes={() => {
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        props.navigation.navigate(SECTION_HEAD);
      }} />
  );
};

ExpensesSummary.useFields = [
  RECEIVE_EXPENSES,
];

ExpensesSummary.showScreen = (props) => props[RECEIVE_EXPENSES];

ExpensesSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
  }),
};

export default ExpensesSummary;
