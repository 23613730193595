import React from 'react';
import {StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import Question from '../../components/Question';
import VeraButton from '../../components/VeraButton';
import {NEXT} from '../../navigators/veraNavigator';
import {TOP_CHARGE_FELONY} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';

const styles = StyleSheet.create({
  button: {
    width: 160,
  },
});

const TopCharge = (props) => {
  const [, setTopCharge] = useReduxState(TOP_CHARGE_FELONY);

  return (
    <Question
      title="Is the most serious charge a felony or misdemeanor?" >
      <VeraButton
        title='Misdemeanor'
        onPress={() => {
          setTopCharge(false);
          props.navigation.push(NEXT);
        }}
        style={styles.button} />
      <VeraButton
        title='Felony'
        secondary
        onPress={() => {
          setTopCharge(true);
          props.navigation.push(NEXT);
        }}
        style={styles.button} />
    </Question>
  );
};

TopCharge.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default TopCharge;
