import {StyleSheet} from 'react-native';
import colors from '../config/colors';

const text = {
  fontSize: 20,
  color: colors.white,
  textAlign: 'center',
};

export const customStyles = StyleSheet.create({
  body: {
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 20,
  },
  line: {
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  header: {
    marginTop: 20,
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerText: {
    ...text,
    fontSize: 25,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  optionalCheck: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  primaryText: {
    ...text,
    fontSize: 24,
    alignSelf: 'center',
    justifyContent: 'center',
    lineHeight: 28,
  },
  secondaryText: {
    ...text,
    fontSize: 21,
    alignSelf: 'center',
    justifyContent: 'flex-start',
  },
  inputSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  drawerLabel: {
    margin: 16,
    fontWeight: 'bold',
  },
  subItemLabel: {
    marginLeft: 24,
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 12,
  },
});

export const customStrip = StyleSheet.create({
  height: 54,
  marginTop: 24,
});

export const customButtons = StyleSheet.create({
  primary: {
    height: 50,
    width: 120,
    alignSelf: 'center',
    backgroundColor: colors.primary,
  },
  title: {
    fontWeight: 'bold',
  },
  helper: {},
  no: {
    height: 50,
    width: 120,
    alignSelf: 'center',
    justifyContent: 'space-around',
    margin: 20,
  },
});
