import React from 'react';
import {StyleSheet, View, ViewPropTypes} from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const Flex = (props) => {
  const {
    children,
    style,
    row,
    rowReverse,
    col,
    colReverse,
    start,
    end,
    center,
    spaceBetween,
    ...rest
  } = props;

  const computedStyle = StyleSheet.flatten([
    styles.container,
    style && style,
    row && {flexDirection: 'row'},
    rowReverse && {flexDirection: 'row-reverse'},
    col && {flexDirection: 'column'},
    colReverse && {flexDirection: 'column-reverse'},
    start && {justifyContent: 'flex-start'},
    end && {justifyContent: 'flex-end'},
    center && {justifyContent: 'center'},
    spaceBetween && {justifyContent: 'space-between'},
  ]);

  return (
    <View style={computedStyle} {...rest}>
      {children}
    </View>
  );
};

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  style: ViewPropTypes.style,
  row: PropTypes.bool,
  rowReverse: PropTypes.bool,
  col: PropTypes.bool,
  colReverse: PropTypes.bool,
  start: PropTypes.bool,
  end: PropTypes.bool,
  center: PropTypes.bool,
  spaceBetween: PropTypes.bool,
};

Flex.defaultProps = {
  row: false,
  rowReverse: false,
  col: false,
  colReverse: false,
  start: false,
  end: false,
  center: false,
  spaceBetween: false,
};

export default Flex;


