/* eslint-disable max-len */
import React from 'react';
import OverlayScroll from '../../layout/OverlayScroll';
import Terms from './Terms';

const TermsOverlay = props => {
  return (
    <OverlayScroll
      fullScreen
      cancelTitle="Disagree"
      completeTitle="Agree"
      indicatorStyle="black"
      footerStyle={{
        height: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? 100
          : 40,
      }}
      {...props}
    >
      <Terms />
    </OverlayScroll>
  );
};

export default TermsOverlay;
