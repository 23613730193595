import React from 'react';
import { StyleSheet, View, ViewPropTypes, Platform } from 'react-native';
import { Text, Button } from 'react-native-elements';
import PropTypes from 'prop-types';
import Flex from './Flex';

import InputScroll from '../components/InputScroll';
import WebCompatibleOverlay from './WebCompatibleOverlay';

const defaultPadding = 16;
const condensedPadding = 8;
const largePadding = 32;

const defaultHeight = '40%';

const styles = StyleSheet.create({
  container: {
    padding: defaultPadding,
    height: '100%',
  },
  header: {
    textAlign: 'center',
    marginBottom: defaultPadding,
  },
  body: {},
  footer: {
    height: 40,
    marginTop: defaultPadding,
  },
});

const OverlayScroll = props => {
  const {
    children,
    containerStyle,
    bodyStyle,
    headerStyle,
    footerStyle,
    title,
    condensed,
    large,
    isVisible,
    onBackdropPress,
    onCancel,
    onComplete,
    completeTitle,
    cancelTitle,
    height,
    width,
    fullScreen,
    indicatorStyle,
    ...rest
  } = props;

  const computedHeaderStyle = StyleSheet.flatten([
    styles.header,
    headerStyle,
    condensed && { marginBottom: condensedPadding },
    large && { marginBottom: largePadding },
  ]);

  const computedFooterStyle = StyleSheet.flatten([
    styles.footer,
    footerStyle,
    condensed && { marginTop: condensedPadding },
    large && { marginTop: largePadding },
  ]);

  const computedBodyStyle = StyleSheet.flatten([styles.body, bodyStyle]);

  const computedContainerStyle = StyleSheet.flatten([
    styles.container,
    containerStyle,
    Platform.OS === 'web' && {
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: 1,
      height: 'calc(100% + 2px)',
      margin: -1,
      width: 'calc(100% + 2px)',
    },
    condensed && { padding: condensedPadding },
    large && { padding: largePadding },
  ]);

  const computedHeight = '100vh';

  const ScrollSection =
    Platform.OS === 'web' ? (
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 88px)',
        }}
      >
        {children}
      </div>
    ) : (
      <InputScroll indicatorStyle={indicatorStyle} style={computedBodyStyle}>
        {children}
      </InputScroll>
    );

  return (
    <WebCompatibleOverlay
      accessibilityViewIsModal={isVisible}
      height={computedHeight}
      width={width}
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      fullScreen={Platform.OS === 'web' || fullScreen}
      overlayStyle={{ padding: 0 }}
    >
      <View style={computedContainerStyle} {...rest}>
        {title && (
          <Text accessibilityRole="header" style={computedHeaderStyle} h4>
            {title}
          </Text>
        )}
        {ScrollSection}
        <View style={computedFooterStyle}>
          <Flex end={!cancelTitle} spaceBetween={Boolean(cancelTitle)}>
            {cancelTitle && (
              <Button
                accessibilityRole="button"
                color="#ef4136"
                onPress={onCancel}
                title={cancelTitle}
                type="clear"
              />
            )}
            <Button
              accessibilityRole="button"
              onPress={onComplete}
              title={completeTitle}
              type="clear"
            />
          </Flex>
        </View>
      </View>
    </WebCompatibleOverlay>
  );
};

OverlayScroll.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyle: ViewPropTypes.style,
  bodyStyle: ViewPropTypes.style,
  headerStyle: PropTypes.object,
  footerStyle: ViewPropTypes.style,
  condensed: PropTypes.bool,
  large: PropTypes.bool,
  onButtonClick: PropTypes.func,
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onBackdropPress: PropTypes.func,
  onComplete: PropTypes.func,
  completeTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelTitle: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullScreen: PropTypes.bool.isRequired,
  indicatorStyle: PropTypes.string,
};

OverlayScroll.defaultProps = {
  condensed: false,
  large: false,
  fullScreen: false,
  completeTitle: 'Done',
};

export default OverlayScroll;
