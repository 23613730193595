import React from 'react';
import {StyleSheet} from 'react-native';
import AboutSection from './AboutSection';
import Link from '../../../components/Link';
import {customStyles} from '../../../config/Styles';
import Paragraph from '../../../layout/Paragraph';

const styles = StyleSheet.create({
  paragraph: {
    textAlign: 'left',
    width: '100%',
  },
});

const Acknowledgments = (props) => {
  const style = StyleSheet.flatten([
    customStyles.secondaryText,
    styles.paragraph,
  ]);


  return (
    <AboutSection title='Acknowledgments' {...props}>
      <Paragraph style={style} large>
        The Vera Institute is proud to partner with Unloop to create the
        ability to pay bail calculator app. Founded in Seattle in 2015,
        Unloop provides learning and employment opportunities in coding
        for people leaving prison. Vera is deeply grateful to Unloop for
        their development of this app, their commitment to providing
        opportunities for people involved in the justice system, and their
        dedication to justice.
      </Paragraph>
      <Paragraph style={style} large>
        To learn more about Unloop and support their work, visit&#8201;
        <Link href='https://www.un-loop.org/'>
          their website
        </Link>.
      </Paragraph>
    </AboutSection>
  );
};

export default Acknowledgments;
