/* eslint-disable max-len */
import React from 'react';
import {View, Text} from 'react-native';
import Markdown, {getUniqueID} from 'react-native-markdown-renderer';
import PropTypes from 'prop-types';
import Section from '../../layout/Section';
import Privacy from '../../../assets/copy/Privacy';
import Terms from '../../../assets/copy/Terms';

const rules = {
  // eslint-disable-next-line react/display-name
  softbreak: () => <Text key={getUniqueID()}> </Text>,
};

const Info = () => {
  return (
    <View>
      <Section
        headerTextStyle={{color: 'black'}}
        title='Vera Bail Assessment Calculator Mobile App End User License Agreement'>
        <Markdown rules={rules}>
          {Terms}
        </Markdown>
      </Section>
      <Section
        headerTextStyle={{color: 'black'}}
        title='Vera Bail Assessment Calculator Mobile App Privacy Policy'>
        <Markdown rules={rules}>
          {Privacy}
        </Markdown>
      </Section>
    </View>
  );
};

Info.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    popToTop: PropTypes.func,
  }),
};

export default Info;
