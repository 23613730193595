import React from 'react';
import {NEXT, SECTION_HEAD} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {
  RECEIVE_ASSETS,
  ASSETS_CASH_HOME_VALUE,
  ASSETS_CASH_PERSON_VALUE,
  ASSETS_CHECKING_VALUE,
  ASSETS_CREDIT_VALUE,
  ASSETS_SAVINGS_VALUE,
  ASSETS_TOTAL,
  toCurrency,
} from '../../sharedFields';
import {useReduxMemo} from '../../veraRedux';

const AssetsSummary = (props) => {
  const assets = [
    ASSETS_CASH_HOME_VALUE,
    ASSETS_CASH_PERSON_VALUE,
    ASSETS_CHECKING_VALUE,
    ASSETS_SAVINGS_VALUE,
  ];

  const assetsTotal = useReduxMemo(
      ASSETS_TOTAL,
      (getField) => {
        if (!getField(RECEIVE_ASSETS)) {
          return 0;
        }

        const total = assets.reduce(
            (total, field) => {
              return total + (getField(field) || 0);
            }, 0
        );
        return total;
      },
      [...assets, RECEIVE_ASSETS]);

  const text =
    `You have ${toCurrency(assetsTotal)} in assets,
and ${toCurrency(props[ASSETS_CREDIT_VALUE])} in credit. `;

  return (
    <YesNoQuestion
      title={text}
      subTitle="Does this sound right?"
      onYes={() => {
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        props.navigation.navigate(SECTION_HEAD);
      }} />
  );
};

AssetsSummary.useFields = [
  RECEIVE_ASSETS,
  ASSETS_CREDIT_VALUE,
];

AssetsSummary.showScreen = (props) => props[RECEIVE_ASSETS];

AssetsSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
  }),
};

export default AssetsSummary;
