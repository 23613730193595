import React from 'react';
import { StyleSheet, Text, View, ViewPropTypes, Platform } from 'react-native';
import PropTypes from 'prop-types';
import InputScroll from '../../components/InputScroll';
import VeraButton from '../../components/VeraButton';
import * as helpText from '../../config/helpText';
import { customStyles } from '../../config/Styles';
import {
  ASSETS_CREDIT_VALUE,
  ASSETS_TOTAL,
  BENEFITS_TOTAL,
  CASH_FACTOR,
  CASH_RECCOMENDATION,
  CREDIT_RECCOMENDATION,
  DISPOSABLE_INCOME,
  EXPENSES_TOTAL,
  INCOME_FACTOR,
  INCOME_TOTAL,
  PARTIALLY_SECURED,
  TOP_CHARGE_FELONY,
  CREDIT_FACTOR,
  EXPECT_INCOME,
  EXPECT_BENEFITS,
  RECEIVE_INCOME,
  RECEIVE_BENEFITS,
} from '../../sharedFields';
import { useReduxMemo, resetState } from '../../veraRedux';
import SummaryItem from './SummaryItem';
import WebCompatibleOverlay from '../../layout/WebCompatibleOverlay';

const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  body: {
    flex: 1,
    maxHeight: isMobileBrowser ? 'calc(100% - 60px)' : undefined,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  header: {
    flexShrink: 1,
  },
  summary: {
    flexShrink: 1,
    maxHeight: isMobileBrowser ? 220 : Platform.OS === 'web' ? 300 : 240,
    marginTop: 10,
    marginBottom: 10,
  },
  description: {},
  button: {
    width: 170,
  },
});

const BailSummary = ({ navigation, style, ...props }) => {
  const computedContainerStyle = StyleSheet.flatten([styles.container, style]);

  useReduxMemo(
    DISPOSABLE_INCOME,
    getField => {
      return (
        getField(INCOME_TOTAL, 0) +
        getField(BENEFITS_TOTAL, 0) -
        getField(EXPENSES_TOTAL, 0)
      );
    },
    [INCOME_TOTAL, BENEFITS_TOTAL, EXPENSES_TOTAL]
  );

  useReduxMemo(
    INCOME_FACTOR,
    getField => {
      const income = getField(DISPOSABLE_INCOME, 0);
      if (income < 100) {
        return 0.1;
      } else if (income < 200) {
        return 0.15;
      } else if (income < 300) {
        return 0.2;
      } else if (income < 500) {
        return 0.25;
      } else {
        return 0.33;
      }
    },
    [DISPOSABLE_INCOME]
  );

  useReduxMemo(
    CASH_FACTOR,
    getField => {
      const assets = getField(ASSETS_TOTAL, 0);
      const felony = getField(TOP_CHARGE_FELONY);

      if (assets < 100) {
        return felony ? 0.66 : 0.25;
      } else if (assets < 200) {
        return felony ? 0.685 : 0.275;
      } else if (assets < 300) {
        return felony ? 0.71 : 0.3;
      } else if (assets < 500) {
        return felony ? 0.735 : 0.325;
      } else {
        return felony ? 0.75 : 0.35;
      }
    },
    [ASSETS_TOTAL, TOP_CHARGE_FELONY]
  );

  const cashReccomendation = useReduxMemo(
    CASH_RECCOMENDATION,
    getField => getField(CASH_FACTOR) * getField(ASSETS_TOTAL, 0),
    [ASSETS_TOTAL, CASH_FACTOR]
  );

  const partiallySecured = useReduxMemo(
    PARTIALLY_SECURED,
    getField => {
      const felony = getField(TOP_CHARGE_FELONY);
      let chargeFactor = felony ? 9 : 5;
      if (!getField(RECEIVE_BENEFITS) && !getField(RECEIVE_INCOME)) {
        chargeFactor--;
      }

      return (
        chargeFactor * getField(INCOME_FACTOR) * getField(DISPOSABLE_INCOME) +
        getField(CASH_RECCOMENDATION)
      );
    },
    [
      EXPECT_INCOME,
      EXPECT_BENEFITS,
      INCOME_FACTOR,
      DISPOSABLE_INCOME,
      CASH_RECCOMENDATION,
      TOP_CHARGE_FELONY,
    ]
  );

  useReduxMemo(
    CREDIT_FACTOR,
    getField => {
      const felony = getField(TOP_CHARGE_FELONY);
      const credit = getField(ASSETS_CREDIT_VALUE, 0);

      if (credit < 250) {
        return felony ? 0.6 : 0.25;
      } else if (credit < 500) {
        return felony ? 0.65 : 0.2;
      } else if (credit < 750) {
        return felony ? 0.7 : 0.25;
      } else if (credit < 1000) {
        return felony ? 0.75 : 0.3;
      } else {
        return felony ? 0.8 : 0.35;
      }
    },
    [TOP_CHARGE_FELONY, ASSETS_CREDIT_VALUE]
  );

  const creditReccomendation = useReduxMemo(
    CREDIT_RECCOMENDATION,
    getField => getField(CREDIT_FACTOR) * getField(ASSETS_CREDIT_VALUE, 0),
    [CREDIT_FACTOR, ASSETS_CREDIT_VALUE]
  );

  const NoPay = () => (
    <View style={styles.summary}>
      <Text style={customStyles.secondaryText}>No ability to pay.</Text>
    </View>
  );

  const usecured = !props[RECEIVE_INCOME] && !props[RECEIVE_BENEFITS];
  const bailBlurb = usecured ? 'unsecured' : 'partially secured';
  const bondHelpTitle = usecured
    ? helpText.UNSECURED_BOND.title
    : helpText.PARTIALLY_SECURED_BOND.title;
  const bondHelpText = usecured
    ? helpText.UNSECURED_BOND.text
    : helpText.PARTIALLY_SECURED_BOND.text;

  const Pay = () => (
    <InputScroll style={styles.summary}>
      {cashReccomendation > 0 && (
        <SummaryItem title="Cash" amount={cashReccomendation} />
      )}
      {partiallySecured > 0 && (
        <SummaryItem
          title="Bond"
          helpText={bondHelpText}
          helpTitle={bondHelpTitle}
          subtitle={bailBlurb}
          amount={partiallySecured}
        />
      )}
      {creditReccomendation > 0 && (
        <SummaryItem title="Credit" amount={creditReccomendation} />
      )}
    </InputScroll>
  );

  const startOver = () => {
    resetState();
    navigation.popToTop();
  };

  return (
    <View style={computedContainerStyle}>
      <View style={styles.body}>
        <View style={styles.header}>
          <Text accessibilityRole="header" style={customStyles.headerText}>
            We&apos;ve calculated your ability to pay bail as:
          </Text>
        </View>
        {partiallySecured > 0 ||
        cashReccomendation > 0 ||
        creditReccomendation > 0 ? (
          <Pay />
        ) : (
          <NoPay />
        )}
        <View style={styles.buttons}>
          <VeraButton
            secondary
            style={styles.button}
            title="New Calculation"
            onPress={() => startOver()}
          />
        </View>
      </View>
    </View>
  );
};

BailSummary.useFields = [
  ASSETS_TOTAL,
  BENEFITS_TOTAL,
  EXPECT_BENEFITS,
  EXPECT_INCOME,
  EXPENSES_TOTAL,
  INCOME_TOTAL,
  RECEIVE_INCOME,
  RECEIVE_BENEFITS,
];

BailSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
    popToTop: PropTypes.func,
  }),
  style: ViewPropTypes.style,
};

export default BailSummary;
