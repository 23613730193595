import {createStackNavigator,
  NavigationActions,
  StackActions} from 'react-navigation';

const SEP_CHAR = '/';

export const NEXT = '@@next';
export const SECTION_HEAD = '@@section_head';

const getSectionName = (routeName) => {
  if (!routeName) {
    return '';
  }

  return routeName.split(SEP_CHAR).slice(0, -1).join(SEP_CHAR);
};

export const createLinearNavigator = (screens, config, getState) => {
  const stackScreens = {};
  const headScreens = {};
  const screenMap = {};
  const headOrder = [];

  let previousPath;
  for (const group in screens) {
    if (Object.prototype.hasOwnProperty.call(screens, group)) {
      for (const entry of screens[group].screens) {
        if (previousPath) screenMap[previousPath] = entry.path;
        previousPath = entry.path;

        if (screens[group].sectionLink && !headScreens[group]) {
          headScreens[group] = entry;
          headOrder.push(group);
        }

        entry.section = group;
        entry.reviewable = screens[group].sectionLink;

        stackScreens[entry.path] = entry;
      }
    }
  }

  const navigator = createStackNavigator(stackScreens, config);

  let furthestSection = -1;
  const getSectionMap = (currentSectionName) => (navigate) => {
    return headOrder.map((header, index) => ({
      name: header,
      selected: currentSectionName === header,
      disabled: index > furthestSection,
      navigate: () => navigate(headScreens[header].path),
    }));
  };

  let reviewingSection = '';
  const reviewSection = (sectionName) => (navigate) => {
    reviewingSection = sectionName;
    navigate(headScreens[sectionName].path);
  };

  const addSectionState = (state, routeName) => {
    if ( state && state.routes && state.routes.length) {
      const route = state.routes[state.routes.length - 1];
      const current = getSectionName(routeName || route.routeName);
      route.getSectionMap = getSectionMap(current);
      route.reviewSection = reviewSection;
    }

    return state;
  };

  const defaultGetStateForAction =
    navigator.router.getStateForAction;

  const getStateForActionWithSection = (action, state) => {
    const defaultState = defaultGetStateForAction(action, state);
    return addSectionState(defaultState, action.routeName);
  };

  const skipScreen = (screen) => {
    if (!screen) {
      return false;
    }

    if (reviewingSection !== '' &&
      screen.reviewable &&
      screen.section !== reviewingSection) {
      return true;
    }

    if (screen.showScreen &&
      !screen.showScreen(
          screen.mapState(getState()))) {
      return true;
    }

    return false;
  };

  const getSectionNumber = (routeName) => {
    const sectionName = getSectionName(routeName);
    const index = headOrder.findIndex((value) => value === sectionName);
    return index;
  };

  const navigateNext = (action, state) => {
    let curRoute = state.routes[state.routes.length - 1].routeName;
    let curScreen;

    do {
      action.routeName = screenMap[curRoute];
      curRoute = action.routeName;
      curScreen = stackScreens[action.routeName];
    } while (skipScreen(curScreen));

    if (!curScreen || curScreen.section !== reviewingSection) {
      reviewingSection = ''; // done reviewing
    }

    const index = getSectionNumber(action.routeName);
    if (index > furthestSection) {
      furthestSection = index;
    }

    return action.routeName ?
      getStateForActionWithSection(action, state) : null;
  };

  const popToTop = (action, state) => {
    furthestSection = -1;

    return getStateForActionWithSection(action, state);
  };

  const pop = (action, state) => {
    const newState = getStateForActionWithSection(action, state);
    const furthest = Math.max(...newState.routes.map(
        (route) => getSectionNumber(route.routeName)
    ));

    furthestSection = furthest;

    return newState;
  };

  const navigateSectionHead = (action, state) => {
    const headRoute = getSectionName(
        state.routes[state.routes.length - 1].routeName
    );

    if (!headScreens[headRoute]) {
      return null;
    }

    reviewingSection = '';

    action.routeName = headScreens[headRoute].path;
    return getStateForActionWithSection(action, state);
  };

  // Following pattern at: https://reactnavigation.org/docs/en/routers.html
  navigator.router.getStateForAction = (action, state) => {
    if (action.type === StackActions.POP_TO_TOP) {
      return popToTop(action, state);
    }

    if (action.type === NavigationActions.BACK) {
      return pop(action, state);
    }

    if (!state ||
      !state.routes ||
      !state.routes.length ||
      (action.type !== NavigationActions.NAVIGATE
        && action.type !== StackActions.PUSH)
    ) {
      return getStateForActionWithSection(action, state);
    }

    switch (action.routeName) {
      case SECTION_HEAD:
        return navigateSectionHead(action, state);
      case NEXT:
        return navigateNext(action, state);
    }

    if (action.routeName.endsWith(SEP_CHAR)) {
      const newScreenRoute = headScreens[action.routeName.slice(0, -1)];
      if (newScreenRoute && newScreenRoute.path) {
        action.routeName = newScreenRoute.path;
      }
    }

    return getStateForActionWithSection(action, state);
  };

  return navigator;
};
