import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AsyncStorage,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
  Platform,
} from 'react-native';
import LabelIcon from '../../components/LabelIcon';
import Strip from '../../layout/Strip';
import TermsOverlay from './TermsOverlay';
import { Linking } from 'expo';
import ActionResponder from '../../components/ActionResponder';

const BG_IMAGE = require('../../../assets/icons/loading.png');
const LOGO = require('../../../assets/images/Vera_WhiteonRed.png');

const styles = StyleSheet.create({
  bgImage: {
    flex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ef4136',
  },
  image: {
    backgroundColor: '#ef4136',
  },
  text: {
    color: 'white',
  },
  logo: {
    flexGrow: 1,
    height: 34,
    marginLeft: 25,
    marginRight: 25,
  },
});

const Cover = ({ navigation }) => {
  const [showAgree, setShowAgree] = useState(false);
  const [agreed, setAgreed] = useState();

  useEffect(() => {
    AsyncStorage.getItem('@eula').then(value => {
      setAgreed(value === 'true');
      setShowAgree(value !== 'true');
    });
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // HACK: hooking up action to close the drawer on resize
      // we are doing it here simply because the navigator is in scope
      // and we are guaranteed to not have a drawer before this component
      // loads
      window.onresize = () => navigation.closeDrawer();
    }
  }, []);

  const agree = answer => {
    AsyncStorage.setItem('@eula', Boolean(answer).toString()).then(() => {
      setShowAgree(false);
      setAgreed(answer);
      navigation.setParams({
        drawerLockMode: answer ? 'unlocked' : 'locked-closed',
      });
    });
  };

  const onPress = () => {
    if (agreed) {
      navigation.navigate('Wizard');
    } else {
      setShowAgree(true);
    }
  };

  return (
    <ImageBackground
      resizeMode="center"
      source={BG_IMAGE}
      style={styles.bgImage}
      imageStyle={styles.image}
      accessible
      accessibilityLabel="Ability to Pay Calculator"
      accessibilityRole="header"
    >
      <TermsOverlay
        isVisible={showAgree}
        onComplete={() => agree(true)}
        onCancel={() => agree(false)}
      />
      <View
        style={{
          width: '100%',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Strip style={{ height: 54 }}>
          {agreed && (
            <LabelIcon
              name="menu"
              type="MaterialCommunityIcons"
              color="white"
              onClick={() => navigation.toggleDrawer()}
              onPress={() => navigation.toggleDrawer()}
              accessibilityHint="Open drawer menu"
            />
          )}
          <ActionResponder
            style={styles.logo}
            onClick={() => Linking.openURL('https://www.vera.org')}
            onPress={() => Linking.openURL('https://www.vera.org')}
            accessibilityRole="image"
            accessibilityLabel="Vera Institute of Justice"
            accessibilityHint="Navigate to Vera homepage"
          >
            <Image style={{ width: 160, height: 34 }} source={LOGO} />
          </ActionResponder>
          <LabelIcon
            name="ios-arrow-forward"
            type="ionicon"
            color="white"
            onPress={onPress}
            iconRight
          >
            <Text style={styles.text}>
              {agreed ? 'Start' : 'User Agreement'}
            </Text>
          </LabelIcon>
        </Strip>
      </View>
    </ImageBackground>
  );
};

Cover.use;

Cover.navigationOptions = ({ navigation }) => {
  return {
    header: null,
    drawerLockMode: navigation.state.params
      ? navigation.state.params.drawerLockMode
      : undefined,
  };
};

Cover.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    toggleDrawer: PropTypes.func,
    setParams: PropTypes.func,
  }),
};

export default Cover;
