import React, {useState} from 'react';
import PropTypes from 'prop-types';
import OptionalInput from './OptioinalInput';

const HookedOptionalInput = ({hook, ...remainder}) => {
  const [value, setValue] = useState(
    hook[0] === undefined ? '' : hook[0].toString()
  );

  return (
    <OptionalInput
      defaultChecked={Boolean(hook[0])}
      value={value}
      onChangeText={
        (text) => {
          const parsed = Number(text);
          hook[1](isNaN(parsed) ? undefined : parsed);
          setValue(text);
        }
      }
      {...remainder}
    />
  );
};

HookedOptionalInput.propTypes = {
  hook: PropTypes.array,
};

export default HookedOptionalInput;
