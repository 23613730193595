import App from './App';
import { AsyncStorage } from 'react-native';
import { registerRootComponent } from 'expo';
import { activateKeepAwake } from 'expo-keep-awake';

// eslint-disable-next-line no-undef
if (__DEV__) {
  activateKeepAwake();
  AsyncStorage.clear();
}

registerRootComponent(App);
