/* eslint-disable max-len */

export const TANF = {
  title: 'Cash Assistance',
  text: 'Cash benefits provided by the government. Examples include Temporary Assistance for Needy Families (TANF) and Safety Net Assistance (SNA).',
};

export const UNEMPLOYMENT = {
  title: 'Unemployment',
  text: 'Benefits paid as a result of unemployment.',
};

export const SS = {
  title: 'Social Security Income',
  text: 'Benefits paid to people who are retired.',
};

export const SSD = {
  title: 'Social Security Disability',
  text: 'Benefits paid to people who have a disability that prevents them from working before reaching retirement age.',
};

export const SSI = {
  title: 'Supplemental Security Income',
  text: 'Benefits paid to people with limited income who are older than 65, blind, or disabled.',
};

export const UNSECURED_BOND = {
  title: 'Unsecured Bond',
  text: 'Unsecured bond with no payment to the court.',
};

export const PARTIALLY_SECURED_BOND = {
  title: 'Partially Secured Bond',
  text: 'Partially secured with 10% payment to the court.',
};

export const OTHER = {
  title: 'Other Benefits',
  text: 'Any other public benefits for which you receive cash or a check.',
};

