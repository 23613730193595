import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {RECEIVE_ASSETS} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';

const AssetsHead = (props) => {
  const [, setReceiveAssets] = useReduxState(RECEIVE_ASSETS);
  return (
    <YesNoQuestion
      // eslint-disable-next-line max-len
      title="Do you have money in a savings or checking account, cash at home, cash in pocket, or a credit card?"
      onYes={() => {
        setReceiveAssets(true);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setReceiveAssets(false);
        props.navigation.push(NEXT);
      }}
    />
  );
};

AssetsHead.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default AssetsHead;
