import React from 'react';
import {StyleSheet} from 'react-native';
import AboutSection from './AboutSection';
import {customStyles} from '../../../config/Styles';
import Paragraph from '../../../layout/Paragraph';
import Link from '../../../components/Link';

const styles = StyleSheet.create({
  paragraph: {
    textAlign: 'left',
    width: '100%',
  },
});

const Background = (props) => {
  const style = StyleSheet.flatten([
    customStyles.secondaryText,
    styles.paragraph,
  ]);


  return (
    <AboutSection title='Background' {...props}>
      <Paragraph style={style} large>
        The Vera Institute of Justice seeks to urgently build and
        improve justice systems that ensure fairness, promote safety,
        and strengthen communities.
      </Paragraph>
      <Paragraph style={style} large>
        One of Vera&apos;s core priorities is bail reform&mdash;ending
        the use of money bail and reducing the number of people in jail.
        As we work towards this goal, we recognize that the vast majority
        of courts across the country still use money bail without any
        real consideration of how much a person can afford. We created
        an ability to pay calculator app to estimate the amount and form of
        bail a person can afford if bail is set. The app consists of
        approximately 30 questions and provides a dollar amount and form,
        such as cash, an unsecured bond, credit card bail, or partially
        secured/deposit bond. This information can influence the bail decision
        and ensure that affordable bail is set. Anyone can use the app,
        including judges, defense attorneys, pretrial services staff, friends
        and family members of the accused, and the accused themselves. The
        information you provide as you answer the app’s questions should
        reflect the financial circumstances—income from employment or benefits,
        assets, and obligations such as rent, food, childcare, and
        transportation&ndash;of the person paying bail, whether that is the
        person accused of a crime or a friend or family member. Please answer
        all questions as accurately as possible so that the bail calculation is
        a realistic reflection of your ability to pay.

        Please contact&#8201;
        <Link
          href='mailto:abilitytopay@vera.org?subject=Ability%20to%20Pay%20App'>
          abilitytopay@vera.org
        </Link>&#8201;
       with any questions or feedback about the app or project.
      </Paragraph>
    </AboutSection>
  );
};

export default Background;
