import React from 'react';
import {Text, View, ViewPropTypes} from 'react-native';
import PropTypes from 'prop-types';
import Button from '../../components/VeraButton';
import {customStyles} from '../../config/Styles';
import useErrorState from '../../hooks/useErrorState';
import {NEXT} from '../../navigators/veraNavigator';
import HookedOptionalInput from '../../components/HookedOptionalInput';
import InputScroll from '../../components/InputScroll';
import {RECEIVE_ASSETS,
  ASSETS_CASH_HOME_VALUE,
  ASSETS_CASH_PERSON_VALUE,
  ASSETS_CHECKING_VALUE,
  ASSETS_CREDIT_VALUE,
  ASSETS_SAVINGS_VALUE,
} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';

const AssetsInput = ({navigation, style}) => {
  const hooks = {
    [ASSETS_CASH_HOME_VALUE]: useReduxState(ASSETS_CASH_HOME_VALUE, false),
    [ASSETS_CASH_PERSON_VALUE]: useReduxState(ASSETS_CASH_PERSON_VALUE, false),
    [ASSETS_CHECKING_VALUE]: useReduxState(ASSETS_CHECKING_VALUE, false),
    [ASSETS_CREDIT_VALUE]: useReduxState(ASSETS_CREDIT_VALUE, false),
    [ASSETS_SAVINGS_VALUE]: useReduxState(ASSETS_SAVINGS_VALUE, false),
  };

  const [, setFieldError, hasError] = useErrorState([
    ASSETS_CASH_HOME_VALUE,
    ASSETS_CASH_PERSON_VALUE,
    ASSETS_CHECKING_VALUE,
    ASSETS_CREDIT_VALUE,
    ASSETS_SAVINGS_VALUE,
  ]);

  return (
    <View style={style}>
      <View style={customStyles.header}>
        <Text
          accessibilityRole='header'
          style={customStyles.headerText}>
        Which assets do you have?
        </Text>
      </View>
      <InputScroll>
        <HookedOptionalInput
          key={ASSETS_CHECKING_VALUE}
          title='A checking account'
          onError={setFieldError(ASSETS_CHECKING_VALUE)}
          hook={hooks[ASSETS_CHECKING_VALUE]}
          perMonth={false}
        />
        <HookedOptionalInput
          key={ASSETS_SAVINGS_VALUE}
          title='A savings account'
          onError={setFieldError(ASSETS_SAVINGS_VALUE)}
          hook={hooks[ASSETS_SAVINGS_VALUE]}
          perMonth={false}
        />
        <HookedOptionalInput
          key={ASSETS_CASH_HOME_VALUE}
          title='Cash at home'
          onError={setFieldError(ASSETS_CASH_HOME_VALUE)}
          hook={hooks[ASSETS_CASH_HOME_VALUE]}
          perMonth={false}
        />
        <HookedOptionalInput
          key={ASSETS_CASH_PERSON_VALUE}
          title='Cash in your pocket'
          onError={setFieldError(ASSETS_CASH_PERSON_VALUE)}
          hook={hooks[ASSETS_CASH_PERSON_VALUE]}
          perMonth={false}
        />
        <HookedOptionalInput
          key={ASSETS_CREDIT_VALUE}
          title='A credit card'
          onError={setFieldError(ASSETS_CREDIT_VALUE)}
          hook={hooks[ASSETS_CREDIT_VALUE]}
          perMonth={false}
        />
      </InputScroll>
      <Button
        secondary
        title='Select'
        disabled={hasError}
        onPress={() => navigation.push(NEXT)} />
    </View>
  );
};

AssetsInput.useFields = [
  RECEIVE_ASSETS,
];

AssetsInput.showScreen = (props) => props[RECEIVE_ASSETS];

AssetsInput.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.punc,
  }),
  style: ViewPropTypes.style,
};

export default AssetsInput;
