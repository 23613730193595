import React from 'react';
import { View, Platform } from 'react-native';
import {
  createAppContainer,
  createDrawerNavigator,
  DrawerItems,
} from 'react-navigation';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import AppLoading from './components/AppLoading';
import { cacheAssets, cacheFonts } from './helpers/AssetsCaching';
import createSingleNavigator from './navigators/singleNavigator';
import reducer from './reducer';
import { createStore } from './veraRedux';
import Cover from './views/assessment/Cover';
import Info from './views/assessment/Info';
import Terms from './views/assessment/Terms';
import Wizard from './veraWizard';
import DrawerSubMenu from './components/DrawerSubmenu';
import scrollUpdater from 'tiny-emitter/instance';

const aboutItems = ['Background', 'FAQ', 'Glossary', 'Acknowledgments'];

const ContentComponent = props => {
  const getLabel = scene => {
    if (scene.route.key === 'About') {
      return (
        <DrawerSubMenu
          items={aboutItems}
          title={scene.route.key}
          color={scene.tintColor}
          labelStyle={props.labelStyle}
          extraLabelStyle={props.extraLabelStyle}
          navigation={props.navigation}
          activeSection={
            scene.route && scene.route.params && scene.route.params.section
          }
          scrollUpdater={scrollUpdater}
        />
      );
    }

    return props.getLabel(scene);
  };

  return <DrawerItems {...props} getLabel={getLabel} />;
};

ContentComponent.propTypes = {
  labelStyle: PropTypes.object,
  extraLabelStyle: PropTypes.object,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  getLabel: PropTypes.func.isRequired,
};

const termsContainerHoc = Component => {
  const TermsContainer = props => (
    <View
      style={{
        padding: 16,
        height: Platform.OS === 'web' ? 'calc(100vh - 64px)' : undefined,
      }}
    >
      <Component {...props} />
    </View>
  );

  return TermsContainer;
};

const navigator = createDrawerNavigator(
  {
    Wizard: Wizard,
    About: createSingleNavigator(Info, 'About', scrollUpdater),
    Terms: createSingleNavigator(
      Terms,
      'Terms and Conditions',
      undefined,
      termsContainerHoc,
      { indicatorStyle: 'black' }
    ),
    Home: {
      screen: Cover,
    },
  },
  {
    initialRouteName: 'Home',
    contentComponent: ContentComponent,
    contentOptions: {
      labelStyle: {
        fontSize: 16,
      },
    },
    overlayColor: Platform.OS === 'web' ? 'rgba(8, 8, 8, 0.6)' : undefined,
  }
);

const MainRoot = createAppContainer(navigator);

const store = createStore(reducer);

const MainRootRedux = props => (
  <Provider store={store}>
    <MainRoot />
  </Provider>
);

export default class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
    };
  }

  /* eslint-disable max-len */
  async _loadAssetsAsync() {
    const imageAssets = cacheAssets([require('../assets/images/cover.png')]);

    const fontAssets = cacheFonts({
      FontAwesome: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf'),
      Ionicons: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
      Entypo: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf'),
      SimpleLineIcons: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
      MaterialIcons: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
      MaterialCommunityIcons: require('../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
    });
    /* eslint-enable max-len */

    await Promise.all([imageAssets, fontAssets]);
  }

  render() {
    if (!this.state.isReady) {
      return (
        <AppLoading
          startAsync={this._loadAssetsAsync}
          onFinish={() => this.setState({ isReady: true })}
        />
      );
    }

    return <MainRootRedux />;
  }
}
