import React from 'react';
import { Platform, StyleSheet, Picker } from 'react-native';

import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  dropDown: {
    backgroundColor: 'white',
    padding: Platform.OS === 'ios' ? 10 : 0,
  },
  picker: {
    marginBottom: 8,
    marginLeft: 8,
    marginTop: -7,
    maxHeight: 42,
  },
});

const VeraSelect = ({ selectedValue, options, onSubmitEditing, ...rest }) => {
  if (Platform.OS !== 'web') {
    const SelectInput = require('react-native-select-input-ios');
    return (
      <SelectInput
        style={styles.dropDown}
        mode="dropdown"
        value={selectedValue}
        labelStyle={{ fontSize: 14, color: 'black' }}
        options={options.map((v, i) => ({ value: i, label: v }))}
        {...rest}
      />
    );
  }

  return (
    <Picker
      style={styles.picker}
      onValueChange={value => onSubmitEditing && onSubmitEditing(value)}
      {...rest}
    >
      {options.map((v, i) => (
        <Picker.Item key={i} label={v} value={i} />
      ))}
    </Picker>
  );
};

VeraSelect.propTypes = {
  selectedValue: PropTypes.any,
  onSubmitEditing: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
};

export default VeraSelect;
