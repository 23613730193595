import React, { useState } from 'react';
import {StyleSheet, ViewPropTypes} from 'react-native';
import {Button} from 'react-native-elements';
import PropTypes from 'prop-types';
import colors from '../config/colors';

const styles = StyleSheet.create({
  button: {
    margin: 10,
    height: 50,
    width: 120,
    alignSelf: 'center',
  },
  primary: {
    backgroundColor: 'white',
    borderColor: colors.primary,
  },
  title: {
    fontWeight: 'bold',
    color: colors.darkGray,
  },
  no: {
    backgroundColor: 'white',
    borderColor: colors.primary,
  },
});

const VeraButton = (props) => {
  const {secondary, style, onPress, disabled, ...remaining} = props;
  const [disableOverride, setOverride] = useState(false)

  const handleOnPress = (event) => {
    setTimeout(() => setOverride(false), 500)
    setOverride(true)  
    onPress(event);
  }
  const computedButtonStyle = StyleSheet.flatten([
    styles.button,
    secondary ? styles.no : styles.primary,
    style,
  ]);

  const computedTitleStyle = StyleSheet.flatten([
    styles.title,
  ]);

  const states = props.disabled ? ['disabled'] : undefined;

  return (
    <Button
      type={secondary ? 'outline' : 'solid' }
      buttonStyle={computedButtonStyle}
      titleStyle={computedTitleStyle}
      accessibilityRole='button'
      disabled={disabled || disableOverride}
      onPress={handleOnPress}
      accessibilityStates={states}
      {...remaining} />
  );
};

VeraButton.propTypes = {
  secondary: PropTypes.bool,
  style: ViewPropTypes.style,
  disabled: PropTypes.bool,
};

VeraButton.propDefaults = {
  secondary: false,
};

export default VeraButton;

export const YesButton = (props) => (
  <VeraButton title='Yes' {...props} />
);

export const NoButton = (props) => (
  <VeraButton secondary title='No' {...props} />
);
