import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import PropTypes from 'prop-types';

const ActionResponder = (props) => {
  const Component = Platform.OS === 'web' ? View : TouchableWithoutFeedback;
  const onClick =
    Platform.OS === 'web' && !props.onClick && !props.disabled
      ? props.onPress
      : undefined;

  const computedStyle = StyleSheet.flatten([
    (props.onClick || onClick) && {cursor: 'pointer'},
    props.disabled && {cursor: 'not-allowed'},
    props.styles,
  ]);

  return <Component onClick={onClick} {...props} style={computedStyle} />;
};

ActionResponder.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onPress: PropTypes.func,
  styles: PropTypes.object,
};

export default ActionResponder;
