import React, { useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import CurrencyInput from '../../components/CurrencyInput';
import VeraButton from '../../components/VeraButton';
import { customStyles } from '../../config/Styles';
import { NEXT } from '../../navigators/veraNavigator';
import {
  INCOME_RATE,
  EXPECT_INCOME,
  RECEIVE_INCOME,
  SELECTED_TYPE,
} from '../../sharedFields';
import { useReduxState } from '../../veraRedux';
import colors from '../../config/colors';
import VeraSelect from '../../components/VeraSelect';

const earnPrompt = 'How much do you earn?';
const expectPrompt = 'How much do you expect to earn?';
const earnInlinePrompt = 'In total, I make';
const expectInlinePrompt = 'In total, I will make';

const styles = StyleSheet.create({
  inputContainer: {
    width: 120,
    alignSelf: 'flex-start',
    marginTop: -7,
    marginBottom: 9,
  },
  inputControl: {
    backgroundColor: colors.white,
  },
  textStyles: {
    alignSelf: 'flex-start',
  },
  dropDown: {
    backgroundColor: 'white',
    padding: Platform.OS === 'ios' ? 10 : 0,
  },
  errorStyle: {
    color: colors.error,
  },
});

const options = ['week', 'month', 'year'];

const IncomeInput = props => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedType, setSelectedType] = useReduxState(SELECTED_TYPE, 'week');
  const [income, setIncome] = useReduxState(INCOME_RATE);
  const [input, setInput] = useState(
    income === undefined ? '' : income.toString()
  );
  const [hasError, setHasError] = useState(false);

  const computedTextStyle = StyleSheet.flatten([
    customStyles.secondaryText,
    styles.textStyles,
  ]);

  const computedInputStyle = StyleSheet.flatten([
    Platform.OS === 'web' && {
      width: '100%',
      marginLeft: -24,
      paddingLeft: 24,
    },
  ]);

  return (
    <View>
      <View style={customStyles.header}>
        <Text style={customStyles.headerText}>
          {props[RECEIVE_INCOME] ? earnPrompt : expectPrompt}
        </Text>
      </View>
      <View style={customStyles.line}>
        <Text style={computedTextStyle}>
          {props[RECEIVE_INCOME] ? earnInlinePrompt : expectInlinePrompt}
        </Text>
        <CurrencyInput
          containerStyle={styles.inputContainer}
          inputStyle={computedInputStyle}
          inputContainerStyle={styles.inputControl}
          placeholder="0.00"
          errorMessage="Error"
          errorStyle={styles.errorStyle}
          value={input}
          onChangeText={text => {
            const parsed = Number(text);
            setIncome(isNaN(parsed) ? undefined : parsed);
            setInput(text);
          }}
          onError={setHasError}
          leftIcon={{
            type: 'material',
            name: 'attach-money',
            containerStyle: { marginLeft: Platform.OS === 'web' ? -15 : 0 },
          }}
        />
        <Text style={computedTextStyle}>per</Text>
        <VeraSelect
          selectedValue={selectedValue}
          options={options}
          onSubmitEditing={value => {
            setSelectedType(options[value]);
            setSelectedValue(value);
          }}
        />
      </View>

      <View style={customStyles.body}>
        <VeraButton
          secondary
          disabled={hasError || !income}
          title="Select"
          onPress={() => {
            props.navigation.push(NEXT, {
              selectedType,
            });
          }}
        />
      </View>
    </View>
  );
};

IncomeInput.useFields = [EXPECT_INCOME, RECEIVE_INCOME];

IncomeInput.showScreen = props => {
  return props[RECEIVE_INCOME] || props[EXPECT_INCOME];
};

IncomeInput.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default IncomeInput;
