import React, { useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { ListItem } from 'react-native-elements';
import PropTypes from 'prop-types';
import { toCurrency } from '../../sharedFields';
import LabelIcon from '../../components/LabelIcon';
import colors from '../../config/colors';
import OverlayScroll from '../../layout/OverlayScroll';
import Paragraph from '../../layout/Paragraph';

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 10,
  },
  rightTitle: {
    width: 150,
    textAlign: 'right',
  },
});

const TitleWithHelp = ({ title, helpText, helpTitle, ...rest }) => (
  <LabelIcon name="help" size={20} color={colors.veraRed} iconRight {...rest}>
    <Text>{title}</Text>
  </LabelIcon>
);

TitleWithHelp.propTypes = {
  title: PropTypes.string,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
};

const SummaryItem = ({
  title,
  subtitle,
  amount,
  helpText,
  helpTitle,
  ...remainder
}) => {
  const [showHelp, setShowHelp] = useState(false);

  const useTitle =
    helpText && helpTitle ? (
      <TitleWithHelp
        title={title}
        helpText={helpText}
        helpTitle={helpTitle}
        onPress={() => setShowHelp(true)}
      />
    ) : (
      title
    );

  return (
    <View>
      <OverlayScroll
        title={helpTitle}
        completeTitle="Done"
        onComplete={() => setShowHelp(false)}
        onBackdropPress={() => setShowHelp(false)}
        isVisible={showHelp}
        containerStyle={{
          padding: 20,
          backgroundColor: Platform.OS === 'web' ? '#373737' : colors.darkGray,
        }}
        headerStyle={{
          color: 'white',
        }}
      >
        <Paragraph style={{ color: 'white' }}>{helpText}</Paragraph>
      </OverlayScroll>
      <ListItem
        accessibilityRole="button"
        accessibilityHint="Revise responses"
        title={useTitle}
        subtitle={subtitle}
        subtitleStyle={styles.subtitle}
        rightTitle={`${toCurrency(amount)}`}
        rightTitleStyle={styles.rightTitle}
        bottomDivider
        {...remainder}
      />
    </View>
  );
};

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  amount: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
};

export default SummaryItem;
