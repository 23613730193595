import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import {YesButton, NoButton} from './VeraButton';

const YesNoQuestion = ({onYes, onNo, ...remaining}) => {
  return (
    <Question {...remaining}>
      <YesButton
        onPress={onYes}
      />
      <NoButton
        onPress={onNo}
      />
    </Question>
  );
};

YesNoQuestion.propTypes = {
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};

export default YesNoQuestion;
