import React from 'react';
import {StyleSheet, View, ViewPropTypes} from 'react-native';
import {Icon} from 'react-native-elements';
import PropTypes from 'prop-types';
import Flex from '../layout/Flex';
import ActionResponder from './ActionResponder';

const condensedMargin = 4;
const defaultMargin = 8;
const largeMargin = 16;

const styles = StyleSheet.create({
  leftIcon: {
    marginRight: defaultMargin,
    marginLeft: 0,
  },
  rightIcon: {
    marginRight: 0,
    marginLeft: defaultMargin,
  },
  container: {
    padding: 4,
    cursor: 'pointer',
  },
});

const LabelIcon = (props) => {
  const {
    children,
    style,
    containerStyle,
    condensed,
    large,
    iconRight,
    size,
    onPress,
    accessibilityRole,
    accessibilityLabel,
    accessibilityHint,
    ...remaining
  } = props;

  const computedStyle = StyleSheet.flatten([
    styles.leftIcon,
    iconRight && styles.rightIcon,
    style && style,
    condensed &&
      (iconRight
        ? {marginLeft: condensedMargin}
        : {marginRight: condensedMargin}),
    large &&
      (iconRight ? {marginLeft: largeMargin} : {marginRight: largeMargin}),
  ]);

  const computedContainerStyle = StyleSheet.flatten([
    styles.container,
    containerStyle,
  ]);

  return (
    <ActionResponder
      style={computedContainerStyle}
      onPress={onPress}
      accessibilityRole={accessibilityRole || (onPress ? 'button' : 'text')}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
    >
      <View style={{height: size}}>
        <Flex rowReverse={iconRight} start={!iconRight} end={iconRight}>
          <Icon containerStyle={computedStyle} size={size} {...remaining} />

          <View style={{height: size}}>
            {children && (
              <Flex col center>
                {children}
              </Flex>
            )}
          </View>
        </Flex>
      </View>
    </ActionResponder>
  );
};

LabelIcon.propTypes = {
  children: PropTypes.node,
  style: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
  condensed: PropTypes.bool,
  large: PropTypes.bool,
  iconRight: PropTypes.bool,
  size: PropTypes.number,
  onPress: PropTypes.func,
  accessibilityRole: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  accessibilityHint: PropTypes.string,
};

LabelIcon.defaultProps = {
  iconRight: false,
  condensed: false,
  large: false,
  size: 32,
};

export default LabelIcon;
