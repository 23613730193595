import React from 'react';
import {StyleSheet, Text as TextNative} from 'react-native';
import {Text} from 'react-native-elements';
import PropTypes from 'prop-types';

const defaultSpacing = 8;
const condensedSpacing = 4;
const largeSpacing = 16;

const styles = StyleSheet.create({
  text: {
    marginTop: defaultSpacing,
    marginBottom: Math.floor(defaultSpacing / 2),
  },
});

const Paragraph = (props) => {
  const {
    style,
    children,
    condensed,
    large,
    ...rest
  } = props;

  const computedStyle = StyleSheet.flatten([
    styles.text,
    style,
    condensed && {
      marginTop: condensedSpacing,
      marginBottom: Math.floor(condensedSpacing / 2),
    },
    large && {
      marginTop: largeSpacing,
      marginBottom: Math.floor(largeSpacing / 2),
    },
  ]);

  return (
    <Text style={computedStyle} {...rest}>
      {children}
    </Text>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node,
  style: TextNative.propTypes.style,
  condensed: PropTypes.bool,
  large: PropTypes.bool,
};

Paragraph.defaultProps = {
  condensed: false,
  large: false,
};

export default Paragraph;
