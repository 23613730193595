import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Text } from 'react-native-elements';
import PropTypes from 'prop-types';
import Flex from './Flex';
import ActionResponder from '../components/ActionResponder';
import colors from '../config/colors';

const styles = StyleSheet.create({
  container: {
    height: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? 104
      : 64,
  },
  body: {
    padding: 12,
  },
  item: {
    paddingTop: 12,
    paddingLeft: 6,
    paddingRight: 6,
  },
  text: {
    fontSize: Platform.OS === 'web' ? 18 : undefined,
  },
  hilight: {
    color: colors.white,
    fontWeight: 'bold',
  },
  primary: {
    color: colors.white,
  },
  disabled: {
    borderColor: colors.lightGray,
    color: colors.lightGray,
  },
});

const NavigationItem = ({ navigate, name, selected, disabled }) => {
  const itemStyle = StyleSheet.flatten([
    styles.item,
    disabled && { cursor: 'not-allowed' },
  ]);

  const textStyle = StyleSheet.flatten([
    styles.text,
    selected ? styles.hilight : styles.primary,
    disabled && styles.disabled,
  ]);

  let states = [];
  if (disabled) {
    states.push('disabled');
  }
  if (selected) {
    states.push('selected');
  }

  if (states === []) {
    states = undefined;
  }

  return (
    <View style={styles.body}>
      <ActionResponder
        style={itemStyle}
        onPress={navigate}
        disabled={disabled}
        accessibilityHint="Jump to section"
        accessibilityStates={states}
      >
        <Text style={textStyle}>{name}</Text>
      </ActionResponder>
    </View>
  );
};

NavigationItem.propTypes = {
  navigate: PropTypes.func,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

const NavigationStrip = ({ getNavAreas, navigation }) => {
  const navigationAreas = getNavAreas(navigation);

  return (
    <View style={styles.container}>
      <Flex center>
        {navigationAreas.length != 0 &&
          navigationAreas.map((area, index) => (
            <NavigationItem key={index} {...area} />
          ))}
      </Flex>
    </View>
  );
};

NavigationStrip.propTypes = {
  getNavAreas: PropTypes.func.isRequired,
  navigation: PropTypes.object,
};

const injectNavAreas = (Component, getNavAreas) => {
  const mergeProps = props =>
    React.createElement(Component, Object.assign({ getNavAreas }, props));
  return mergeProps;
};

const InjectedNavigationStrip = injectNavAreas(NavigationStrip, nav => {
  return nav.state.getSectionMap(nav.push.bind(nav));
});

export default InjectedNavigationStrip;
