import React from 'react';
import { Platform, StyleSheet, Text, View, ViewPropTypes } from 'react-native';
import PropTypes from 'prop-types';
import SectionSummaryItem from './SectionSummaryItem';
import InputScroll from '../../components/InputScroll';
import VeraButton from '../../components/VeraButton';
import { customStyles } from '../../config/Styles';
import { NEXT } from '../../navigators/veraNavigator';
import {
  ASSETS_TOTAL,
  BENEFITS_TOTAL,
  EXPENSES_TOTAL,
  INCOME_TOTAL,
} from '../../sharedFields';
import {
  IncomeSection,
  BenefitsSection,
  ExpensesSection,
  AssetsSection,
} from '../../sectionNames';

const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  body: {
    flex: 1,
    maxHeight: isMobileBrowser ? 'calc(100% - 60px)' : undefined,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  header: {
    flexShrink: 1,
  },
  summary: {
    flexShrink: 1,
    maxHeight: isMobileBrowser ? 220 : Platform.OS === 'web' ? 300 : 240,
    marginTop: 10,
    marginBottom: 10,
  },
  description: {},
  buttons: {},
});

const SectionSummary = ({ navigation, style, ...props }) => {
  const computedContainerStyle = StyleSheet.flatten([styles.container, style]);

  const review = section => () => {
    navigation.state.reviewSection(section)(navigation.push.bind(navigation));
  };

  return (
    <View style={computedContainerStyle}>
      <View style={styles.body}>
        <View style={styles.header}>
          <Text style={customStyles.headerText} accessibilityRole="header">
            According to what we have discussed, you have about:
          </Text>
        </View>
        <InputScroll style={styles.summary}>
          <SectionSummaryItem
            title="Income"
            amount={props[INCOME_TOTAL] || 0}
            onPress={review(IncomeSection)}
            perMonth
          />
          <SectionSummaryItem
            title="Benefits"
            amount={props[BENEFITS_TOTAL] || 0}
            onPress={review(BenefitsSection)}
            perMonth
          />
          <SectionSummaryItem
            title="Assets"
            amount={props[ASSETS_TOTAL] || 0}
            onPress={review(AssetsSection)}
          />
          <SectionSummaryItem
            title="Expenses"
            amount={props[EXPENSES_TOTAL] || 0}
            onPress={review(ExpensesSection)}
            perMonth
          />
        </InputScroll>
        <View style={styles.description}>
          <Text style={customStyles.primaryText}>
            Please revise your responses if this does not look correct.
          </Text>
        </View>
        <View style={styles.buttons}>
          <VeraButton
            secondary
            title="Submit"
            onPress={() => navigation.push(NEXT)}
          />
        </View>
      </View>
    </View>
  );
};

SectionSummary.useFields = [
  ASSETS_TOTAL,
  BENEFITS_TOTAL,
  EXPENSES_TOTAL,
  INCOME_TOTAL,
];

SectionSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
    state: PropTypes.shape({
      reviewSection: PropTypes.func.isRequired,
    }),
  }),
  style: ViewPropTypes.style,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
};

export default SectionSummary;
