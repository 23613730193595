module.exports =
`This Mobile Application End User License Agreement ("**Agreement**") is a
binding agreement between you ("**End User**" or "**you**") and Vera Institute
of Justice, Inc. ("**Company**"). This Agreement governs your use of the Ability
to Pay Calculator Application (including all related documentation, the
"**Application**"). The Application is licensed, not sold, to you.

BY DOWNLOADING, INSTALLING, USING, AND/OR PROVIDING INFORMATION TO OR THROUGH
THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS
AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) ACCEPT
THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT
AGREE TO THESE TERMS, DO NOT DOWNLOAD THE APPLICATION.

1.  *License Grant*. Subject to the terms of this Agreement, Company grants you
    a limited, non-exclusive, and nontransferable license to download, install,
    and use the Application for your use on a single mobile device owned or
    otherwise controlled by you ("**Mobile Device**") strictly in accordance
    with the Application's documentation.

2.  *License Restrictions*. Licensee shall not:

    1.  copy the Application, except as expressly permitted by this license;

    2.  modify, translate, adapt, or otherwise create derivative works or
        improvements, whether or not patentable, of the Application;

    3.  reverse engineer, disassemble, decompile, decode, or otherwise attempt
        to derive or gain access to the source code of the Application or any
        part thereof;

    4.  remove, delete, alter, or obscure any trademarks or any copyright,
        trademark, patent, or other intellectual property or proprietary rights
        notices from the Application, including any copy thereof; or

    5.  rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer, or otherwise make available the Application, or any features
        or functionality of the Application, to any third party for any reason,
        including by making the Application available on a network where it is
        capable of being accessed by more than one device at any time.

3.  *Reservation of Rights*. You acknowledge and agree that the Application is
    provided under license, and not sold, to you. You do not acquire any
    ownership interest in the Application under this Agreement, or any other
    rights thereto other than to use the Application in accordance with the
    license granted, and subject to all terms, conditions, and restrictions,
    under this Agreement. Company reserves and shall retain its entire right,
    title, and interest in and to the Application, including all copyrights,
    trademarks, and other intellectual property rights therein or relating
    thereto, except as expressly granted to you in this Agreement.

4.  *Collection and Use of Your Information*. You acknowledge that you may
    provide certain information about yourself or third parties as a condition
    to downloading, installing, or using the Application or certain of its
    features or functionality. All information we collect through or in
    connection with this Application is subject to our Privacy Policy below. By
    downloading, installing, using, and providing information to or through this
    Application, you (i) consent to all actions taken by us with respect to your
    information in compliance with the Privacy Policy; and (ii) represent and
    warrant that you have obtained lawful informed consent from any third party
    whose information you provide.

5.  *Geographic Restrictions*. The Content and Services are based in the state
    of New York in the United States and provided for access and use only by
    persons located in the United States. You acknowledge that you may not be
    able to access all or some of the Content and Services outside of the United
    States and that access thereto may not be legal by certain persons or in
    certain countries. If you access the Content and Services from outside the
    United States, you are responsible for compliance with local laws.

6.  *Updates*. Company may from time to time in its sole discretion develop and
    provide Application updates, which may include upgrades, bug fixes, patches,
    other error corrections, and/or new features (collectively, including
    related documentation, "**Updates**"). Updates may also modify or delete in
    their entirety certain features and functionality. You agree that Company
    has no obligation to provide any Updates or to continue to provide or enable
    any particular features or functionality. Based on your Mobile Device
    settings, when your Mobile Device is connected to the internet either:

    1.  the Application will automatically download and install all available
        Updates; or

    2.  you may receive notice of or be prompted to download and install
        available Updates.

You shall promptly download and install all Updates and acknowledge and agree
that the Application or portions thereof may not properly operate should you
fail to do so. You further agree that all Updates will be deemed part of the
Application and be subject to all terms and conditions of this Agreement.

1.  *Third-Party Materials*. The Application may display, include, or make
    available third-party content (including data, information, applications,
    and other products, services, and/or materials) or provide links to
    third-party websites or services, including through third-party advertising
    ("**Third-Party Materials**"). You acknowledge and agree that Company is not
    responsible for Third-Party Materials, including their accuracy,
    completeness, timeliness, validity, copyright compliance, legality, decency,
    quality, or any other aspect thereof. Company does not assume and will not
    have any liability or responsibility to you or any other person or entity
    for any Third-Party Materials. Third-Party Materials and links thereto are
    provided solely as a convenience to you, and you access and use them
    entirely at your own risk and subject to such third parties' terms and
    conditions.

2.  *Term and Termination*.

    1.  The term of Agreement commences when you download the
        Application/acknowledge your acceptance and will continue in effect
        until terminated by you or Company as set forth in this Section 9.

    2.  You may terminate this Agreement by deleting the Application and all
        copies thereof from your Mobile Device.

    3.  Company may terminate this Agreement at any time without notice. In
        addition, this Agreement will terminate immediately and automatically
        without any notice if you violate any of the terms and conditions of
        this Agreement.

    4.  Upon termination:

        1.  all rights granted to you under this Agreement will also terminate;
            and

        2.  you must cease all use of the Application and delete all copies of
            the Application from your Mobile Device and account.

    5.  Termination will not limit any of Company's rights or remedies at law or
        in equity.

3.  *Disclaimer of Warranties*. THE APPLICATION IS PROVIDED TO LICENSEE "AS IS"
    AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM
    EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON
    BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE
    PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
    STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
    TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
    DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION
    TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO
    REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS,
    ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER
    SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
    MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY
    ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED
WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER,
SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.

1.  *Limitation of Liability*. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
    LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
    RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM
    OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT
    AND SERVICES FOR:

    1.  PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS
        OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION,
        COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL,
        INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.

    2.  DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT
        ACTUALLY PAID BY YOU FOR THE APPLICATION.

THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF
CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO
SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.

1.  *Indemnification*. You agree to indemnify, defend, and hold harmless Company
    and its officers, directors, employees, agents, affiliates, successors, and
    assigns from and against any and all losses, damages, liabilities,
    deficiencies, claims, actions, judgments, settlements, interest, awards,
    penalties, fines, costs, or expenses of whatever kind, including reasonable
    attorneys' fees, arising from or relating to your use or misuse of the
    Application or your breach of this Agreement, including but not limited to
    the content you submit or make available through this Application.

2.  *Severability*. If any provision of this Agreement is illegal or
    unenforceable under applicable law, the remainder of the provision will be
    amended to achieve as closely as possible the effect of the original term
    and all other provisions of this Agreement will continue in full force and
    effect; provided, however, that if any fundamental term or provision of this
    Agreement is invalid, illegal, or unenforceable, the remainder of this
    Agreement shall be unenforceable.

3.  *Governing Law*. This Agreement is governed by and construed in accordance
    with the internal laws of the State of New York without giving effect to any
    choice or conflict of law provision or rule. Any legal suit, action, or
    proceeding arising out of or related to this Agreement or the Application
    shall be instituted exclusively in the federal courts of the United States
    or the courts of the State of New York in each case located in New York, NY.
    You waive any and all objections to the exercise of jurisdiction over you by
    such courts and to venue in such courts.

4.  *Entire Agreement*. This Agreement and our Privacy Policy constitute the
    entire agreement between you and Company with respect to the Application and
    supersede all prior or contemporaneous understandings and agreements,
    whether written or oral, with respect to the Application.

5.  *Waiver*. No failure to exercise, and no delay in exercising, on the part of
    either party, any right or any power hereunder shall operate as a waiver
    thereof, nor shall any single or partial exercise of any right or power
    hereunder preclude further exercise of that or any other right hereunder. In
    the event of a conflict between this Agreement and any applicable purchase
    or other terms, the terms of this Agreement shall govern.`;
