import React from 'react';
import {Text, View, ViewPropTypes} from 'react-native';
import PropTypes from 'prop-types';
import {customStyles} from '../../config/Styles';
import {NEXT} from '../../navigators/veraNavigator';
import VeraButton from '../../components/VeraButton';
import * as helpText from '../../config/helpText';
import useErrorState from '../../hooks/useErrorState';
import {
  EXPECT_BENEFITS,
  RECEIVE_BENEFITS,
  BENEFITS_OTHER_VALUE,
  BENEFITS_SS_VALUE,
  BENEFITS_SSD_VALUE,
  BENEFITS_SSI_VALUE,
  BENEFITS_TANF_VALUE,
  BENEFITS_UNEMPLOYMENT_VALUE,
} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';
import HookedOptionalInput from '../../components/HookedOptionalInput';
import InputScroll from '../../components/InputScroll';

const BenefitsInput = ({navigation, style, ...props}) => {
  const hooks = {
    [BENEFITS_OTHER_VALUE]: useReduxState(BENEFITS_OTHER_VALUE),
    [BENEFITS_SS_VALUE]: useReduxState(BENEFITS_SS_VALUE),
    [BENEFITS_SSD_VALUE]: useReduxState(BENEFITS_SSD_VALUE),
    [BENEFITS_SSI_VALUE]: useReduxState(BENEFITS_SSI_VALUE),
    [BENEFITS_TANF_VALUE]: useReduxState(BENEFITS_TANF_VALUE),
    [BENEFITS_UNEMPLOYMENT_VALUE]:
      useReduxState(BENEFITS_UNEMPLOYMENT_VALUE),
  };

  const [, setFieldError, hasError] = useErrorState([
    BENEFITS_OTHER_VALUE,
    BENEFITS_SS_VALUE,
    BENEFITS_SSD_VALUE,
    BENEFITS_SSI_VALUE,
    BENEFITS_TANF_VALUE,
    BENEFITS_UNEMPLOYMENT_VALUE,
  ]);

  const will = props[EXPECT_BENEFITS] ? 'will' : 'do';
  return (
    <View style={style}>
      <View style={customStyles.header}>
        <Text
          accessibilityRole='header'
          style={customStyles.headerText}>
          Which benefits {will} you receive?
        </Text>
      </View>
      <InputScroll>
        <HookedOptionalInput
          key={BENEFITS_TANF_VALUE}
          title='Cash assistance, like TANF'
          helpText={helpText.TANF.text}
          helpTitle={helpText.TANF.title}
          onError={setFieldError(BENEFITS_TANF_VALUE)}
          hook={hooks[BENEFITS_TANF_VALUE]}
        />

        <HookedOptionalInput
          key={BENEFITS_UNEMPLOYMENT_VALUE}
          title='Unemployment income'
          helpText={helpText.UNEMPLOYMENT.text}
          helpTitle={helpText.UNEMPLOYMENT.title}
          onError={setFieldError(BENEFITS_UNEMPLOYMENT_VALUE)}
          hook={hooks[BENEFITS_UNEMPLOYMENT_VALUE]}
        />
        <HookedOptionalInput
          key={BENEFITS_SSI_VALUE}
          title='Supplemental Security Income (SSI)'
          helpText={helpText.SSI.text}
          helpTitle={helpText.SSI.title}
          onError={setFieldError(BENEFITS_SSI_VALUE)}
          hook={hooks[BENEFITS_SSI_VALUE]}
        />
        <HookedOptionalInput
          key={BENEFITS_SSD_VALUE}
          title='Social Security Disability (SSD/SSDI)'
          helpText={helpText.SSD.text}
          helpTitle={helpText.SSD.title}
          onError={setFieldError(BENEFITS_SSD_VALUE)}
          hook={hooks[BENEFITS_SSD_VALUE]}
        />
        <HookedOptionalInput
          key={BENEFITS_SS_VALUE}
          title='Social Security'
          helpText={helpText.SS.text}
          helpTitle={helpText.SS.title}
          onError={setFieldError(BENEFITS_SS_VALUE)}
          hook={hooks[BENEFITS_SS_VALUE]}
        />
        <HookedOptionalInput
          key={BENEFITS_OTHER_VALUE}
          helpText={helpText.OTHER.text}
          helpTitle={helpText.OTHER.title}
          onError={setFieldError(BENEFITS_OTHER_VALUE)}
          title='Other income from benefits'
          hook={hooks[BENEFITS_OTHER_VALUE]}
        />
      </InputScroll>
      <VeraButton
        secondary
        title='Select'
        disabled={hasError}
        onPress={() => navigation.push(NEXT)} />
    </View>
  );
};

BenefitsInput.useFields = [
  EXPECT_BENEFITS,
  RECEIVE_BENEFITS,
];

BenefitsInput.showScreen =
  (props) => props[RECEIVE_BENEFITS] || props[EXPECT_BENEFITS];

BenefitsInput.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
  style: ViewPropTypes.style,
};

export default BenefitsInput;
