import React from 'react';
import PropTypes from 'prop-types';
import SummaryItem from './SummaryItem';
import ActionResponder from '../../components/ActionResponder';

const SectionSummaryItem = ({title, amount, perMonth, onPress}) => (
  <ActionResponder
    onPress={onPress}
    accessible
    accessibilityRole='button'
    accessibilityHint='Revise responses'
  >
    <SummaryItem
      title={title}
      subtitle={perMonth && 'per month'}
      amount={amount}
      chevron
    />
  </ActionResponder>
);

SectionSummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  perMonth: PropTypes.bool,
  onPress: PropTypes.func,
};

SectionSummaryItem.defaultValues = {
  perMonth: false,
};

export default SectionSummaryItem;
