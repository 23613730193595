import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, Text, ViewPropTypes, Platform } from 'react-native';
import { Input } from 'react-native-elements';
import PropTypes from 'prop-types';
import colors from '../config/colors';

const defaultInputColor = colors.darkGray;
const styles = StyleSheet.create({
  errorStyle: {},
  inputStyle: {
    textAlign: 'center',
    color: defaultInputColor,
    width: '100%',
  },
});

const defaultErrorMessage = 'Invalid amount';

const currencyRegEx = /^\d+(\.\d{2})?$/;

const CurrencyInput = React.forwardRef((props, ref) => {
  const {
    onChangeText,
    onError,
    inputStyle,
    inputContainerStyle,
    errorStyle,
    errorMessage,
    color,
    placeholderTextColor,
    perMonth,
    ...remaining
  } = props;

  const [hasError, setHasError] = useState(false);

  const onValidateChangeText = useCallback(
    text => {
      setHasError(text && !text.match(currencyRegEx));
      return onChangeText && onChangeText(text);
    },
    [currencyRegEx, onChangeText]
  );

  useEffect(() => {
    onError && onError(hasError);
  }, [hasError]);

  const computedInputStyle = StyleSheet.flatten([
    styles.inputStyle,
    inputStyle,
    color && { color: color },
  ]);

  const computedInputContainerStyle = StyleSheet.flatten([
    inputContainerStyle,
    hasError && { borderColor: colors.veraRed },
  ]);

  const computedErrorStyle = StyleSheet.flatten([
    styles.errorStyle,
    errorStyle,
  ]);

  const computedErrorMessage = errorMessage || defaultErrorMessage;
  const placeholder = perMonth ? '0.00 per month' : '0.00';

  return (
    <Input
      ref={ref}
      errorMessage={hasError ? computedErrorMessage : undefined}
      errorStyle={computedErrorStyle}
      placeholder={placeholder}
      returnKeyType="done"
      keyboardType={Platform.OS === 'web' ? undefined : 'decimal-pad'}
      autoCorrect={false}
      inputStyle={computedInputStyle}
      inputContainerStyle={computedInputContainerStyle}
      onChangeText={onValidateChangeText}
      placeholderTextColor={placeholderTextColor || color || defaultInputColor}
      {...remaining}
    />
  );
});

CurrencyInput.displayName = 'CurrencyInput';

CurrencyInput.propTypes = {
  onChangeText: PropTypes.func,
  onError: PropTypes.func,
  inputStyle: Text.propTypes.style,
  inputContainerStyle: ViewPropTypes.style,
  errorMessage: PropTypes.string,
  errorStyle: Text.propTypes.style,
  color: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  perMonth: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  perMonth: true,
};

export default CurrencyInput;
