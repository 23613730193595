
import {Platform} from 'react-native';

export const TOP_CHARGE_FELONY = 'top-charge-felony';

export const HOURS_WORKED = 'hours-worked';
export const INCOME_RATE = 'income-rate';
export const INCOME_TOTAL = 'income-total';
export const SELECTED_TYPE = 'selected-type';
export const RECEIVE_ASSETS = 'receive-assets';
export const RECEIVE_BENEFITS = 'receive-benefits';
export const RECEIVE_EXPENSES = 'receive-expenses';
export const RECEIVE_INCOME = 'receive-income';
export const EXPECT_INCOME = 'expect-income';

export const ASSETS_CASH_HOME_VALUE = 'assets-cash-home-value';
export const ASSETS_CASH_PERSON_VALUE = 'assets-cash-person-value';
export const ASSETS_CHECKING_VALUE = 'assets-checking-value';
export const ASSETS_CREDIT_VALUE= 'assets-credit-value';
export const ASSETS_SAVINGS_VALUE = 'assets-savings-value';
export const ASSETS_TOTAL = 'assets-total';

export const BENEFITS_TANF_VALUE = 'benefits-tanf';
export const BENEFITS_UNEMPLOYMENT_VALUE = 'benefits-unemployment';
export const BENEFITS_SSI_VALUE = 'benefits-ssi';
export const BENEFITS_SSD_VALUE = 'benefits-ssd';
export const BENEFITS_SS_VALUE = 'benefits-ss';
export const BENEFITS_OTHER_VALUE = 'benefits-other';
export const BENEFITS_TOTAL = 'benefits-total';
export const EXPECT_BENEFITS = 'expect-benefits';

export const EXPENSES_HOUSING_VALUE = 'expenses-housing-value';
export const EXPENSES_ELECTRIC_GAS_VALUE = 'expenses-electric-gas-value';
export const EXPENSES_FOOD_VALUE = 'expenses-food-value';
export const EXPENSES_TRANSPORTATION_VALUE = 'expenses-transportation-value';
export const EXPENSES_PHONE_VALUE = 'expenses-phone-value';
export const EXPENSES_INTERNET_TV_VALUE = 'expenses-internet-tv-value';
export const EXPENSES_LOANS_VALUE = 'expenses-loans-value';
export const EXPENSES_CHILD_VALUE = 'expenses-child-value';
export const EXPENSES_MEDICAL_VALUE = 'expenses-medical-value';
export const EXPENSES_OTHER_VALUE = 'expenses-other-value';
export const EXPENSES_TOTAL = 'expenses-total';

export const DISPOSABLE_INCOME = 'disposable-income';
export const INCOME_FACTOR = 'income-factor';
export const CASH_FACTOR = 'cash-factor';
export const CASH_RECCOMENDATION = 'case-reccomendation';
export const PARTIALLY_SECURED = 'patially-secured';
export const CREDIT_RECCOMENDATION = 'credit-reccomendation';
export const CREDIT_FACTOR = 'credit-factor';

if (Platform.OS === 'android') {
  require('intl');
  require('intl/locale-data/jsonp/en-US');
}

const getLocale = () => {
  if (Platform.OS === 'android') {
    return 'en-US'; // only supporting en-US for android
  }

  return undefined;
};

const formatter =
  new Intl.NumberFormat(getLocale(), {style: 'currency', currency: 'USD'});

export const toCurrency = (number) => {
  return formatter.format(number);
};
