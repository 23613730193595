import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import {RECEIVE_BENEFITS, EXPECT_BENEFITS} from '../../sharedFields';
import YesNoQuestion from '../../components/YesNoQuestion';
import {useReduxState} from '../../veraRedux';

const BenefitHead = (props) => {
  const [, setReceiveBenefits] = useReduxState(RECEIVE_BENEFITS);
  const [, setExpectBenefits] = useReduxState(EXPECT_BENEFITS);

  return (
    <YesNoQuestion
      title="Do you currently receive income from benefits?"
      onYes={() => {
        setReceiveBenefits(true);
        setExpectBenefits(false);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setReceiveBenefits(false);
        props.navigation.push(NEXT);
      }} />
  );
};

BenefitHead.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default BenefitHead;
