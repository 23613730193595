import {createStackNavigator} from 'react-navigation';
import getContainedScreen from '../layout/ScreenContainer';
import leftHeaderHoc from './leftHeaderHoc';
import makeScrollable from './makeScrollable';

const createSingleNavigator =
  (Screen, title, scrollUpdater, containerHoc, scrollProps) => {
    const scrollable = makeScrollable(Screen, scrollUpdater, scrollProps);

    const containedScreen = !containerHoc ?
      getContainedScreen()(scrollable)
      : containerHoc(scrollable);

    return createStackNavigator(
        {
          Screen: {
            screen: containedScreen,
            navigationOptions: ({navigation}) => {
              const extraOptions = containedScreen.navigationOptions ?
                containedScreen.navigationOptions(navigation) : {};
              return ({
                headerTitle: title,
                headerLeft: leftHeaderHoc(navigation),
                ...extraOptions,
              });
            },
          },
        },
    );
  };

export default createSingleNavigator;
