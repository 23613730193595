import React, { useEffect, useState, useCallback } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import colors from '../config/colors';
import Flex from './Flex';

import OverlayScroll from '../layout/OverlayScroll';
import Glossary from '../views/assessment/About/Glossary';

const styles = StyleSheet.create({
  container: {
    height: Platform.OS === 'web' ? 'calc(100vh - 64px)' : undefined,
    padding: Platform.OS === 'web' ? 16 : undefined,
    width: '100%',
    backgroundColor: colors.darkGray,
  },
  body: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 20,
    paddingBottom: 10,
    flexGrow: 1,
    flexShrink: 1,
    flex: 1,
  },
});

export const mapStateToPropsHoc = Component => state => {
  const props = {};

  if (Component.useFields) {
    for (const field of Component.useFields) {
      props[field] = state.form[field];
    }
  }

  return props;
};

const mapDispatchToPropsHoc = Component => state => {
  return {
    // do nothing, no implementation
  };
};

const getContainedScreen = NavigationStrip => (Component, glossaryEvents) => {
  const ScreenContainer = React.forwardRef((props, ref) => {
    const [glossary, setGlossary] = useState(false);

    const onGlossary = useCallback(value => setGlossary(value), [setGlossary]);
    useEffect(() => {
      if (glossaryEvents) {
        glossaryEvents.on('showGlossary', onGlossary);
        return () => glossaryEvents.off('showGlossary', onGlossary);
      }
    }, [onGlossary, glossaryEvents]);

    return (
      <View style={styles.container}>
        <OverlayScroll
          containerStyle={{
            backgroundColor: colors.darkGray,
            maxHeight:
              Platform.OS === 'web' ? 'calc(100vh - 100px)' : undefined,
          }}
          isVisible={glossary}
          onBackdropPress={() => setGlossary(false)}
          onComplete={() => setGlossary(false)}
          indicatorStyle="white"
          fullScreen
          footerStyle={{
            height: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
              ? 100
              : 40,
          }}
        >
          <Glossary />
        </OverlayScroll>
        <View style={{ margin: 'auto', maxWidth: 800, height: '100%' }}>
          <Flex col>
            <Component ref={ref} style={styles.body} {...props} />
            {NavigationStrip && (
              <NavigationStrip navigation={props.navigation} />
            )}
          </Flex>
        </View>
      </View>
    );
  });

  const displayName =
    Component.displayName ||
    (typeof Component === 'function' && Component.name) ||
    (typeof Component === 'object' && Component.constructor.name);

  ScreenContainer.displayName = `Contained<${displayName}>`;
  hoistNonReactStatics(ScreenContainer, Component);

  ScreenContainer.propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  const mapStateToProps = mapStateToPropsHoc(Component);
  const mapDispatchToProps = mapDispatchToPropsHoc(Component);

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScreenContainer);
};

export default getContainedScreen;
