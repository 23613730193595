/* eslint-disable max-len */
module.exports =
`Last modified: 10/24/2019

*Introduction*

Vera Institute of Justice, Inc. ("**Vera**" or "**We**") respects your privacy
and is committed to protecting it through our compliance with this policy. This
policy describes:

-   The types of information we may collect or that you may provide when you
    download, install, access, or use the Vera Ability to Pay Calculator
    Application (the "**App**").

-   Our practices for collecting, using, maintaining, protecting, and disclosing
    that information.

This policy applies only to information we collect in this App. This policy DOES
NOT apply to information that:

-   We collect offline or on any Vera websites; or

-   You provide to or is collected by any third party (see *Third-Party
    Information Collection*).

Our website and these other third parties have their own privacy policies, which
we encourage you to read before providing information on or through them.

Please read this policy carefully. If you do not agree with our policies and
practices, do not download or use this App. By downloading or using this App,
you agree to this privacy policy. This policy may change from time to time (see
*Changes to Our Privacy Policy*). Your continued use of this App after we revise
this policy means you accept those changes, so please check the policy
periodically for updates.

*Children Under the Age of 13*

The App is not intended for children under 13 years of age, and we do not
knowingly collect personal information from children under 13. If we learn we
have collected or received personal information from a child under 13 without
verification of parental consent, we will delete that information. If you
believe we might have any information from or about a child under 13, please
contact us at
[abilitytopay@vera.org](mailto:abilitytopay\\@vera.org?subject=Ability%20To%20Pay%20App).

*Information We Collect and How We Collect It*

We collect information from and about users of our App:

-   Directly from you when you provide it to us.

-   Automatically when you use the App.

*Information You Provide to Us*

-   We may also ask you for information when you report a problem with the App.

-   We may collect records and copies of your correspondence (including email
    addresses and phone numbers), if you contact us.

-   The data that you input into the App for calculation purposes is used by the
    App solely for the purpose of making the calculation. We do not access or
    store any such data, or use it for any other purpose. You represent and
    warrant that you have obtained informed consent from any individual whose
    data you input into the App.

*Automatic Information Collection*

When you download, access, and use the App, it may use technology to
automatically collect:

-   **Usage Details.** When you access and use the App, we will only store or
    collect data regarding your agreement or non-agreement to the user terms.

If you do not want us to collect this information, do not download the App or
delete it from your device.

*Information Collection Technologies*

The technologies we use for automatic information collection may include:

-   **Cookies (or mobile cookies).** A cookie is a small file placed on your
    smartphone. It may be possible to refuse to accept mobile cookies by
    activating the appropriate setting on your smartphone. However, if you
    select this setting you may be unable to access certain parts of our App.

-   **Web Beacons.** Pages of the App [and our emails] may contain small
    electronic files known as web beacons (also referred to as clear gifs, pixel
    tags, and single-pixel gifs) that permit the Vera, for example, to count
    users who have visited those pages [or opened an email] and for other
    related app statistics (for example, recording the popularity of certain app
    content and verifying system and server integrity).

*Third-Party Information Collection*

When you use the App or its content, certain third parties may use automatic
information collection technologies to collect information about you or your
device. These third parties may include analytics companies, your mobile device
manufacturer, or your mobile service provider.

These third parties may use tracking technologies to collect information about
you when you use this app. The information they collect may be associated with
your personal information or they may collect information, including personal
information, about your online activities over time and across different
websites, apps, and other online services websites. They may use this
information to provide you with interest-based (behavioral) advertising or other
targeted content.

**We do not control these third parties' tracking technologies or how they may
be used.** If you have any questions about an advertisement or other targeted
content, you should contact the responsible provider directly.

*How We Use Your Information*

We use information that we collect about you or that you provide to us,
including any personal information, to:

-   Provide you with the App and its contents so that you can perform a bail
    assessment calculation.

-   Notify you when App updates are available.

*Disclosure of Your Information*

We may disclose aggregated information about our users as we reasonably
determine.

In addition, we may disclose information that we collect or you provide:

-   For any purpose disclosed by us when you provide the information.

-   With your consent.

-   To comply with any court order, law, or legal process, including to respond
    to any government or regulatory request.

*Data Security*

Unfortunately, the transmission of information via the internet and mobile
platforms is not completely secure, and we cannot guarantee the security of your
information transmitted through our App. As noted, we will not store or use such
information except as provided herein. Any transmission of personal or other
information is at your own risk.

*Changes to Our Privacy Policy*

We may update our privacy policy from time to time. If we make material changes
to how we treat our users' information, we will post the new privacy policy on
this page.

*Contact Information*

To ask questions or comment about this privacy policy and our privacy practices,
contact us at:

[abilitytopay@vera.org](mailto:abilitytopay\\@vera.org?subject=Ability%20To%20Pay%20App).`;
