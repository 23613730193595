import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Link from '../components/Link';
import OverlayScroll from '../layout/OverlayScroll';
import Glossary from '../views/assessment/About/Glossary';
import colors from '../config/colors';

const styles = StyleSheet.create({
  label: {
    paddingRight: 8,
    textDecorationLine: 'none',
    fontWeight: 'bold',
  },
});

const RightHeader = ({ onGlossary }) => {
  const [glossary, setGlossary] = useState(false);

  return (
    <React.Fragment>
      <OverlayScroll
        containerStyle={{ backgroundColor: colors.darkGray }}
        isVisible={glossary}
        onBackdropPress={() => setGlossary(false)}
        onComplete={() => setGlossary(false)}
        indicatorStyle="white"
        fullScreen
      >
        <Glossary />
      </OverlayScroll>
      <Link
        style={styles.label}
        onPress={() => onGlossary()}
        accessibilityRole="button"
      >
        Glossary
      </Link>
    </React.Fragment>
  );
};

export default RightHeader;
