
import React from 'react';
import PropTypes from 'prop-types';
import AboutSection from './AboutSection';
import {StyleSheet} from 'react-native';
import {customStyles} from '../../../config/Styles';
import Paragraph from '../../../layout/Paragraph';

/* eslint-disable max-len */
const questions = [
  {
    question: 'Who should use this calculator?',
    answer: 'This app is a tool designed to help provide more information about a person’s ability to pay bail. Anybody can use this app: attorneys, pretrial services agencies, or family members. In the scenario that bail might be set on somebody you know, whether that person is a client or a family member, this app calculates that person’s ability to pay bail so that judges know exactly what amount of bail would be reasonable to afford. ',
  },
  {
    question: 'How will my answers be used?',
    answer: 'Your privacy is a top priority. Any information provided to the calculator will only be used to determine a person’s ability to pay bail. The data will not be saved. Once you close out of the app, the data is erased.',
  },
  {
    question: 'Should children and dependents be included in this calculation?',
    answer: 'If the respondent is financially responsible for children and dependents, yes. If a person uses their income from employment or benefits to pay for the care of others, then those expenses should be reported to accurately account for money coming in and money going out. ',
  },
  {
    question: 'How does ability to pay factor into bail decisions?',
    answer: 'Most states have laws that require the courts to consider a person’s financial circumstances when setting a money bail amount. However, courts rarely, if ever, ask how much a person can afford before setting bail. There have been lawsuits—in Houston, San Francisco, St. Louis, and dozens of other cities—challenging how courts set bail without taking into account a person’s ability to pay. As a result, many courts are changing their bail practices to individually consider a person’s financial circumstances. A new bail law in New York is the first in the country to require judges to not only consider a person’s ability to pay and whether setting an amount of bail will cause undue hardship but also requiring judges to set unsecured and partially secured bonds as an option. ',
  },
];
  /* eslint-enable max-len */

const styles = StyleSheet.create({
  paragraph: {
    textAlign: 'left',
    width: '100%',
  },
  question: {
    fontWeight: 'bold',
  },
});

const answerStyle = StyleSheet.flatten([
  customStyles.secondaryText,
  styles.paragraph,
]);

const questionStyle = StyleSheet.flatten([
  customStyles.secondaryText,
  styles.paragraph,
  styles.question,
]);

const Question = ({question, answer}) => (
  <React.Fragment>
    <Paragraph style={questionStyle}>
      {question}
    </Paragraph>
    <Paragraph style={answerStyle}>
      {answer}
    </Paragraph>
  </React.Fragment>
);

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

const FAQ = (props) => {
  return (
    <AboutSection title='Frequently Asked Questions' {...props}>
      {questions.map( (item, index) => (
        <Question key={index} question={item.question} answer={item.answer} />
      ))}
    </AboutSection>
  );
};

export default FAQ;
