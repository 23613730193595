import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { CheckBox } from 'react-native-elements';
import PropTypes from 'prop-types';
import { customStyles } from '../config/Styles';
import colors from '../config/colors';
import LabelIcon from './LabelIcon';
import OverlayScroll from '../layout/OverlayScroll';
import Paragraph from '../layout/Paragraph';
import CurrencyInput from './CurrencyInput';

const OptionalInput = props => {
  const {
    title,
    defaultChecked,
    fullScreen,
    helpText,
    helpTitle,
    onChangeText,
    ...remaining
  } = props;

  const [checked, setChecked] = useState(Boolean(defaultChecked));
  const [focused, setFocused] = useState(false);

  const ref = useRef();

  const handlePress = () => {
    setChecked(!checked);
    setFocused(!checked);
  };

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.darkGray,
    },
    inputContainer: {
      backgroundColor: colors.white,
      marginLeft: '15%',
      marginRight: '15%',
      marginTop: 10,
      marginBottom: 10,
    },
    errorStyle: {
      marginLeft: '15%',
      marginRight: '15%',
      color: colors.error,
      borderColor: 'white',
    },
  });

  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (focused) {
      ref.current.focus();
    }
  }, [focused]);

  useEffect(() => {
    if (!checked) {
      onChangeText('');
    }
  }, [checked]);

  return (
    <View>
      {helpTitle && (
        <OverlayScroll
          title={helpTitle}
          completeTitle="Done"
          onComplete={() => setShowHelp(false)}
          onBackdropPress={() => setShowHelp(false)}
          isVisible={showHelp}
          containerStyle={{
            padding: 20,
            backgroundColor:
              Platform.OS === 'web' ? '#373737' : colors.darkGray,
          }}
          headerStyle={{
            color: 'white',
          }}
        >
          <Paragraph style={{ color: 'white' }}>{helpText}</Paragraph>
        </OverlayScroll>
      )}
      <View style={styles.container}>
        <CheckBox
          accessibilityHint={`checkbox is ${checked ? 'checked' : 'unchecked'}`}
          containerStyle={customStyles.optionalCheck}
          checked={checked}
          checkedColor={colors.darkGray}
          onPress={handlePress}
          textStyle={{ marginRight: 16 }}
          title={title}
        />
        {helpTitle && helpText && (
          <LabelIcon
            accessibilityLabel="help"
            accessibilityHint={`what is '${title}'?`}
            name="help"
            size={20}
            color={colors.white}
            onPress={() => setShowHelp(true)}
          />
        )}
      </View>
      {checked && (
        <CurrencyInput
          inputContainerStyle={styles.inputContainer}
          errorStyle={styles.errorStyle}
          ref={ref}
          onChangeText={onChangeText}
          placeholderTextColor={colors.lightGray}
          {...remaining}
          {...(Platform.OS !== 'web'
            ? { accessibilityHint: `Enter value for field: ${title}` }
            : {})}
        />
      )}
    </View>
  );
};

OptionalInput.propTypes = {
  title: PropTypes.string,
  defaultChecked: PropTypes.bool,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
  onChangeText: PropTypes.func,
};

export default OptionalInput;
