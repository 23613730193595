import React from 'react';
import {StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import {customStyles} from '../../../config/Styles';
import Paragraph from '../../../layout/Paragraph';

const styles = StyleSheet.create({
  paragraph: {
    borderBottomColor: '#ccc',
    borderBottomWidth: 2,
  },
});

const AboutSection = ({children, title, ...rest}) => {
  const headingStyle = StyleSheet.flatten([
    customStyles.headerText,
    styles.paragraph,
  ]);

  return (
    <View {...rest}>
      <Paragraph
        accessibilityRole='header'
        style={headingStyle}
        large>
        {title}
      </Paragraph>
      {children}
    </View>
  );
};

AboutSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default AboutSection;
