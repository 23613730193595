import React from 'react';
import {Text, View, ViewPropTypes} from 'react-native';
import PropTypes from 'prop-types';
import {customStyles} from '../../config/Styles';
import {NEXT} from '../../navigators/veraNavigator';
import HookedOptionalInput from '../../components/HookedOptionalInput';
import {RECEIVE_EXPENSES,
  EXPENSES_CHILD_VALUE,
  EXPENSES_ELECTRIC_GAS_VALUE,
  EXPENSES_FOOD_VALUE,
  EXPENSES_HOUSING_VALUE,
  EXPENSES_INTERNET_TV_VALUE,
  EXPENSES_LOANS_VALUE,
  EXPENSES_MEDICAL_VALUE,
  EXPENSES_OTHER_VALUE,
  EXPENSES_PHONE_VALUE,
  EXPENSES_TRANSPORTATION_VALUE,
} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';
import InputScroll from '../../components/InputScroll';
import VeraButton from '../../components/VeraButton';
import useErrorState from '../../hooks/useErrorState';

const ExpensesInput = ({navigation, style}) => {
  const hooks = {
    [EXPENSES_CHILD_VALUE]: useReduxState(EXPENSES_CHILD_VALUE),
    [EXPENSES_ELECTRIC_GAS_VALUE]:
      useReduxState(EXPENSES_ELECTRIC_GAS_VALUE),
    [EXPENSES_FOOD_VALUE]: useReduxState(EXPENSES_FOOD_VALUE),
    [EXPENSES_HOUSING_VALUE]: useReduxState(EXPENSES_HOUSING_VALUE),
    [EXPENSES_INTERNET_TV_VALUE]: useReduxState(EXPENSES_INTERNET_TV_VALUE),
    [EXPENSES_LOANS_VALUE]: useReduxState(EXPENSES_LOANS_VALUE),
    [EXPENSES_MEDICAL_VALUE]: useReduxState(EXPENSES_MEDICAL_VALUE),
    [EXPENSES_OTHER_VALUE]: useReduxState(EXPENSES_OTHER_VALUE),
    [EXPENSES_PHONE_VALUE]: useReduxState(EXPENSES_PHONE_VALUE),
    [EXPENSES_TRANSPORTATION_VALUE]:
      useReduxState(EXPENSES_TRANSPORTATION_VALUE),
  };

  const [, setFieldError, hasError] = useErrorState([
    EXPENSES_CHILD_VALUE,
    EXPENSES_ELECTRIC_GAS_VALUE,
    EXPENSES_FOOD_VALUE,
    EXPENSES_HOUSING_VALUE,
    EXPENSES_INTERNET_TV_VALUE,
    EXPENSES_LOANS_VALUE,
    EXPENSES_MEDICAL_VALUE,
    EXPENSES_OTHER_VALUE,
    EXPENSES_PHONE_VALUE,
    EXPENSES_TRANSPORTATION_VALUE,
  ]);

  return (
    <View style={style}>
      <View style={customStyles.header}>
        <Text
          accessibilityRole='header'
          style={customStyles.headerText}>
          Which expenses do you have?
        </Text>
      </View>
      <InputScroll>
        <HookedOptionalInput
          key={EXPENSES_HOUSING_VALUE}
          title='Housing'
          onError={setFieldError(EXPENSES_HOUSING_VALUE)}
          hook={hooks[EXPENSES_HOUSING_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_ELECTRIC_GAS_VALUE}
          title='Electric/Gas'
          onError={setFieldError(EXPENSES_ELECTRIC_GAS_VALUE)}
          hook={hooks[EXPENSES_ELECTRIC_GAS_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_FOOD_VALUE}
          title='Food and groceries'
          onError={setFieldError(EXPENSES_FOOD_VALUE)}
          hook={hooks[EXPENSES_FOOD_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_TRANSPORTATION_VALUE}
          title='Transportation'
          onError={setFieldError(EXPENSES_TRANSPORTATION_VALUE)}
          hook={hooks[EXPENSES_TRANSPORTATION_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_INTERNET_TV_VALUE}
          title='Internet, TV, Phone'
          onError={setFieldError(EXPENSES_INTERNET_TV_VALUE)}
          hook={hooks[EXPENSES_INTERNET_TV_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_LOANS_VALUE}
          title='Loan (Student/Car/etc...)'
          onError={setFieldError(EXPENSES_LOANS_VALUE)}
          hook={hooks[EXPENSES_LOANS_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_CHILD_VALUE}
          title='Child Support'
          onError={setFieldError(EXPENSES_CHILD_VALUE)}
          hook={hooks[EXPENSES_CHILD_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_MEDICAL_VALUE}
          title='Medical Bills'
          onError={setFieldError(EXPENSES_MEDICAL_VALUE)}
          hook={hooks[EXPENSES_MEDICAL_VALUE]}
        />
        <HookedOptionalInput
          key={EXPENSES_OTHER_VALUE}
          title='Other Major Expenses'
          onError={setFieldError(EXPENSES_OTHER_VALUE)}
          hook={hooks[EXPENSES_OTHER_VALUE]}
        />
      </InputScroll>
      <VeraButton
        secondary
        title='Select'
        disabled={hasError}
        onPress={() => navigation.push(NEXT)} />
    </View>
  );
};

ExpensesInput.useFields = [
  RECEIVE_EXPENSES,
];

ExpensesInput.showScreen = (props) => props[RECEIVE_EXPENSES];

ExpensesInput.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
  style: ViewPropTypes.style,
};

export default ExpensesInput;
