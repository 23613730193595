import React, {useRef, useEffect, useState} from 'react';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import {Platform} from '@unimodules/core';

const InputScroll = ({children, scrollTo, ...remaining}) => {
  const [contentSize, setContentSize] = useState(0);
  const [layoutSize, setLayoutSize] = useState(0);
  const [enabled, setEnabled] = useState(false);

  const ref = useRef();
  const ref2 = useRef();

  useEffect(() => {
    if (enabled && Platform.OS === 'ios') {
      ref.current.flashScrollIndicators();
    }
  }, [ref.current, enabled]);

  useEffect(() => {
    setEnabled(Boolean(contentSize && layoutSize && contentSize > layoutSize));
  }, [contentSize, layoutSize]);

  useEffect(() => {
    if (ref2.current && enabled) {
      ref2.current.scrollToPosition(0, scrollTo);
    }
  }, [ref2.current, enabled, scrollTo]);

  const onSize = (x, y) => {
    setContentSize(y);
  };

  const onLayout = (e) => {
    setLayoutSize(e.nativeEvent.layout.height);
  };

  return (
    <KeyboardAwareScrollView
      ref={ref2}
      innerRef={(r) => (ref.current = r)}
      indicatorStyle='white'
      persistentScrollbar={enabled}
      enableOnAndroid={enabled}
      scrollEnabled={enabled}
      onContentSizeChange={onSize}
      onLayout={onLayout}
      style={{maxHeight: Platform.OS === 'web' ? '50vh' : undefined}}
      {...remaining}
    >
      {children}
    </KeyboardAwareScrollView>
  );
};

InputScroll.propTypes = {
  children: PropTypes.node,
  scrollTo: PropTypes.number,
};

export default InputScroll;
