import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {EXPECT_INCOME, RECEIVE_INCOME} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';


const ExpectedIncome = (props) => {
  const [, setReceiveIncome] = useReduxState(RECEIVE_INCOME);
  const [, setExpectIncome] = useReduxState(EXPECT_INCOME);

  return (
    <YesNoQuestion
      title=
        "Do you expect to receive income from employment in the next 30 days?"
      onYes={() => {
        setExpectIncome(true);
        setReceiveIncome(false);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setExpectIncome(false);
        props.navigation.push(NEXT);
      }} />
  );
};

ExpectedIncome.useFields = [
  RECEIVE_INCOME,
];

ExpectedIncome.showScreen = (props) => !props[RECEIVE_INCOME];

ExpectedIncome.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ExpectedIncome;
