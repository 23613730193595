import React, {useEffect, useState} from 'react';
import {
  Image,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import Background from './About/Background';
import Glossay from './About/Glossary';
import FAQ from './About/FAQ';
import Acknowledgments from './About/Acknowledgments';

const LOGO = require('../../../assets/images/Vera_WhiteonGray.png');

const Info = ({scrollTo, scrollSection}) => {
  const [faqLayoutTop, setFaqLayoutTop] = useState();
  const [backgroundLayoutTop, setBackgroundLayoutTop] = useState();
  const [glossaryLayoutTop, setGlossaryLayoutTop] = useState();
  const [acknowledgmentsLayoutTop, setAcknowledgmentsLayoutTop] = useState();

  const onLayout = (setter) => (e) => {
    setter(e.nativeEvent.layout.y);
  };

  useEffect(() => {
    switch (scrollSection) {
      case 'Background':
        if (backgroundLayoutTop !== undefined) {
          scrollTo(backgroundLayoutTop);
        }
        return;
      case 'FAQ':
        if (faqLayoutTop !== undefined) {
          scrollTo(faqLayoutTop);
        }
        return;
      case 'Glossary':
        if (glossaryLayoutTop !== undefined) {
          scrollTo(glossaryLayoutTop);
        }
        return;
      case 'Acknowledgments':
        if (acknowledgmentsLayoutTop !== undefined) {
          scrollTo(acknowledgmentsLayoutTop);
        }
        return;
      default:
        return;
    }
  }, [scrollSection,
    faqLayoutTop !== undefined &&
    backgroundLayoutTop &&
    glossaryLayoutTop &&
    acknowledgmentsLayoutTop]);

  // TODO: fix the padding hack (need to figure out why the
  // KeyboardAvoidingScrollView pushed the content down/ won't
  // scroll all the way)
  return (
    <View style={{paddingBottom: 50}}>
      <Image
        style={{width: 160, height: 34}}
        source={LOGO} />
      <Background onLayout={onLayout(setBackgroundLayoutTop)} />
      <FAQ onLayout={onLayout(setFaqLayoutTop)} />
      <Glossay onLayout={onLayout(setGlossaryLayoutTop)} />
      <Acknowledgments onLayout={onLayout(setAcknowledgmentsLayoutTop)} />
    </View>
  );
};

Info.propTypes = {
  scrollTo: PropTypes.func,
  scrollSection: PropTypes.string,
};

export default Info;
