import React from 'react';
import {StyleSheet, View, ViewPropTypes} from 'react-native';
import PropTypes from 'prop-types';
import Flex from './Flex';

const defaultPaddingH = 20;
const defaultPaddingV = 10;

const condensedPaddingH = 10;
const condensedPaddingV = 5;

const largePaddingH = 40;
const largePaddingV = 20;

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  flex: {
    paddingRight: defaultPaddingH,
    paddingLeft: defaultPaddingH,
    paddingTop: defaultPaddingV,
    paddingBottom: defaultPaddingV,
  },
  condensedFlex: {
    paddingRight: condensedPaddingH,
    paddingLeft: condensedPaddingH,
    paddingTop: condensedPaddingV,
    paddingBottom: condensedPaddingV,
  },
  largeFlex: {
    paddingRight: largePaddingH,
    paddingLeft: largePaddingH,
    paddingTop: largePaddingV,
    paddingBottom: largePaddingV,
  },
});

const Toolbar = (props) => {
  const {
    children,
    style,
    condensed,
    large,
    ...rest
  } = props;

  const computedStyle = StyleSheet.flatten([
    style,
    styles.container,
  ]);

  const computedFlexStyle = StyleSheet.flatten([
    styles.flex,
    condensed && styles.condensedFlex,
    large && styles.largeFlex,
  ]);

  return (
    <View style={computedStyle} {...rest}>
      <Flex style={computedFlexStyle} spaceBetween >
        {children}
      </Flex>
    </View>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  style: ViewPropTypes.style,
  condensed: PropTypes.bool,
  large: PropTypes.bool,
};

Toolbar.defaultProps = {
  condensed: false,
  large: false,
};

export default Toolbar;
