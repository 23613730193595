import React from 'react';
import {NEXT} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {RECEIVE_EXPENSES} from '../../sharedFields';
import {useReduxState} from '../../veraRedux';

const ExpensesHead = (props) => {
  const [, setReceiveExpenses] = useReduxState(RECEIVE_EXPENSES);
  return (
    <YesNoQuestion
      title="Do you have monthly expenses such as rent, groceries, etc.?"
      onYes={() => {
        setReceiveExpenses(true);
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        setReceiveExpenses(false);
        props.navigation.push(NEXT);
      }} />
  );
};

ExpensesHead.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ExpensesHead;
