import React from 'react';
import {
  EXPECT_INCOME,
  INCOME_RATE,
  INCOME_TOTAL,
  RECEIVE_INCOME,
  SELECTED_TYPE,
  toCurrency,
} from '../../sharedFields';
import {NEXT, SECTION_HEAD} from '../../navigators/veraNavigator';
import PropTypes from 'prop-types';
import YesNoQuestion from '../../components/YesNoQuestion';
import {useReduxMemo} from '../../veraRedux';

const IncomeSummary = (props) => {
  const incomeTotal = useReduxMemo(
      INCOME_TOTAL,
      (getField) => {
        if (!getField(RECEIVE_INCOME)
          && !getField(EXPECT_INCOME)) {
          return 0;
        }

        const income = getField(INCOME_RATE);
        const type = getField(SELECTED_TYPE);

        switch (type) {
          case 'week':
            return income * 52 / 12;
          case 'month':
            return income;
          case 'year':
            return income / 12;
        }
      },
      [EXPECT_INCOME, RECEIVE_INCOME, INCOME_RATE, SELECTED_TYPE]
  );

  const will = props[EXPECT_INCOME] ? 'will ' : '';
  const text = `You ${will}make about ${toCurrency(incomeTotal)} per month,
    and roughly ${toCurrency(incomeTotal * 12)} per year.`;

  return (
    <YesNoQuestion
      title={text}
      subTitle="Does this sound right?"
      onYes={() => {
        props.navigation.push(NEXT);
      }}
      onNo={() => {
        props.navigation.navigate(SECTION_HEAD);
      }} />
  );
};

IncomeSummary.useFields = [
  EXPECT_INCOME,
  RECEIVE_INCOME,
];

IncomeSummary.showScreen = (props) =>
  props[RECEIVE_INCOME] || props[EXPECT_INCOME];

IncomeSummary.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    push: PropTypes.func,
    popToTop: PropTypes.func,
  }),
};

export default IncomeSummary;
