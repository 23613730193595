import React from 'react';
import { Text } from 'react-native';
import { customStyles } from './config/Styles';
import NavigationStrip from './layout/NavigationStrip';
import getContainedScreen, {
  mapStateToPropsHoc,
} from './layout/ScreenContainer';
import { createVeraNavigator } from './navigators/veraNavigator';
import { getState } from './veraRedux';
import AssetsHead from './views/assets/AssetsHead';
import AssetsInput from './views/assets/AssetsInput';
import AssetsSummary from './views/assets/AssetsSummary';
import BenefitHead from './views/benefits/BenefitHead';
import BenefitsInput from './views/benefits/BenefitsInput';
import BenefitsSummary from './views/benefits/BenefitsSummary';
import ExpectedBenefits from './views/benefits/ExpectedBenefits';
import ExpensesHead from './views/expenses/ExpensesHead';
import ExpensesInput from './views/expenses/ExpensesInput';
import ExpensesSummary from './views/expenses/ExpensesSummary';
import ExpectedIncome from './views/income/ExpectedIncome';
import IncomeHead from './views/income/IncomeHead';
import IncomeInput from './views/income/IncomeInput';
import IncomeSummary from './views/income/IncomeSummary';
import TopCharge from './views/intro/TopCharge';
import BailSummary from './views/summary/BailSummary';
import SectionSummary from './views/summary/SectionSummary';
import {
  IncomeSection,
  BenefitsSection,
  AssetsSection,
  ExpensesSection,
} from './sectionNames';
import colors from './config/colors';

const Wizard = createVeraNavigator(
  [
    {
      name: '',
      sectionLink: false,
      screens: [
        {
          screen: TopCharge,
          name: 'top-charge',
        },
      ],
    },
    {
      name: IncomeSection,
      screens: [
        {
          screen: IncomeHead,
          name: 'income-head',
        },
        {
          screen: ExpectedIncome,
          name: 'expected-income',
        },
        {
          screen: IncomeInput,
          name: 'income-input',
        },
        {
          screen: IncomeSummary,
          name: 'summary',
        },
      ],
    },
    {
      name: BenefitsSection,
      screens: [
        {
          screen: BenefitHead,
          name: 'benefit-head',
        },
        {
          screen: ExpectedBenefits,
          name: 'expected-benefits',
        },
        {
          screen: BenefitsInput,
          name: 'benefits-input',
          veraOptions: {
            makeScrollable: false,
          },
        },
        {
          screen: BenefitsSummary,
          name: 'benefits-summary',
        },
      ],
    },
    {
      name: AssetsSection,
      screens: [
        {
          screen: AssetsHead,
          name: 'assets-head',
        },
        {
          screen: AssetsInput,
          name: 'assets-input',
          veraOptions: {
            makeScrollable: false,
          },
        },
        {
          screen: AssetsSummary,
          name: 'assets-summary',
        },
      ],
    },
    {
      name: ExpensesSection,
      screens: [
        {
          screen: ExpensesHead,
          name: 'expenses-head',
        },
        {
          screen: ExpensesInput,
          name: 'expense-sinput',
          veraOptions: {
            makeScrollable: false,
          },
        },
        {
          screen: ExpensesSummary,
          name: 'expenses-summary',
        },
      ],
    },
    {
      name: 'Calculation',
      sectionLink: false,
      screens: [
        {
          screen: SectionSummary,
          name: 'section-summary',
          veraOptions: {
            containerHoc: getContainedScreen(),
            makeScrollable: false,
          },
        },
        {
          screen: BailSummary,
          name: 'bail-summary',
          veraOptions: {
            containerHoc: getContainedScreen(),
            makeScrollable: false,
          },
        },
      ],
    },
  ],
  {
    defaultVeraOptions: {
      containerHoc: getContainedScreen(NavigationStrip),
      mapStateHoc: mapStateToPropsHoc,
      makeScrollable: true,
    },
    navigationOptions: () => ({
      title: 'test',
      drawerLabel: drawerLabel,
    }),
  },
  getState
);

const drawerLabel = () => (
  <Text
    style={[
      customStyles.drawerLabel,
      {
        color: colors.veraRed,
        fontSize: 16,
        marginTop: 32,
      },
    ]}
  >
    Calculate ability to pay
  </Text>
);

export default Wizard;
